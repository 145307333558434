import { PayloadAction } from "@reduxjs/toolkit";
import {
  HeaderI,
  deleteApi,
  getApi,
  postApi,
  protectedPostApi,
} from "../utils/helpers/api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  assignToStrategyFailure,
  assignToStrategySuccess,
  compileStrategiesFailure,
  compileStrategiesSuccess,
  createStrategiesFailure,
  createStrategiesSuccess,
  deleteAssignedTickerFailure,
  deleteAssignedTickerSuccess,
  getSavedStrategiesFailure,
  getSavedStrategiesSuccess,
  getStrategiesFailure,
  getStrategiesSuccess,
  saveStrategiesFailure,
  saveStrategiesSuccess,
} from "../redux/reducers/StrategiesReducer";

function* handleCreateStrategiesRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      "strategies/manage",
      action?.payload,
      headers
    );
    if (response?.data?.statusCode == 201) {
      yield put(createStrategiesSuccess(response?.data));
    } else {
      yield put(createStrategiesFailure(response?.data));
    }
  } catch (error: any) {
    yield put(createStrategiesFailure(error?.response?.data));
  }
}

function* handleGetStrategiesRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(getApi, "strategies/manage", headers);
    if (response?.data?.statusCode == 200) {
      yield put(getStrategiesSuccess(response?.data));
    } else {
      yield put(getStrategiesFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getStrategiesFailure(error?.response?.data));
  }
}

function* handleCompileStrategiesRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      "strategies/compile",
      action?.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(compileStrategiesSuccess(response?.data));
    } else {
      yield put(compileStrategiesFailure(response?.data));
    }
  } catch (error: any) {
    yield put(compileStrategiesFailure(error?.response?.data));
  }
}

function* handleSaveStrategiesRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      "strategies/save",
      action?.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(saveStrategiesSuccess(response?.data));
    } else {
      yield put(saveStrategiesFailure(response?.data));
    }
  } catch (error: any) {
    yield put(saveStrategiesFailure(error?.response?.data));
  }
}

function* handleGetSavedStrategiesRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      getApi,
      `strategies/save?strategy_id=${action?.payload}`,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(getSavedStrategiesSuccess(response?.data));
    } else {
      yield put(getSavedStrategiesFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getSavedStrategiesFailure(error?.response?.data));
  }
}

function* handleAssignToStrategyRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      `strategies/assign`,
      action.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(assignToStrategySuccess(response?.data));
    } else {
      yield put(assignToStrategyFailure(response?.data));
    }
  } catch (error: any) {
    yield put(assignToStrategyFailure(error?.response?.data));
  }
}

function* handleDeleteAssignedTickerRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      deleteApi,
      `strategies/assign?id=${action.payload?.id}&ticker=${action.payload?.ticker}`,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(deleteAssignedTickerSuccess(response?.data));
    } else {
      yield put(deleteAssignedTickerFailure(response?.data));
    }
  } catch (error: any) {
    yield put(deleteAssignedTickerFailure(error?.response?.data));
  }
}

const watchFunctions = [
  (function* () {
    yield takeLatest(
      "Strategies/createStrategiesRequest",
      handleCreateStrategiesRequest
    );
  })(),
  (function* () {
    yield takeLatest(
      "Strategies/getStrategiesRequest",
      handleGetStrategiesRequest
    );
  })(),
  (function* () {
    yield takeLatest(
      "Strategies/compileStrategiesRequest",
      handleCompileStrategiesRequest
    );
  })(),
  (function* () {
    yield takeLatest(
      "Strategies/saveStrategiesRequest",
      handleSaveStrategiesRequest
    );
  })(),
  (function* () {
    yield takeLatest(
      "Strategies/getSavedStrategiesRequest",
      handleGetSavedStrategiesRequest
    );
  })(),
  (function* () {
    yield takeLatest(
      "Strategies/assignToStrategyRequest",
      handleAssignToStrategyRequest
    );
  })(),
  (function* () {
    yield takeLatest(
      "Strategies/deleteAssignedTickerRequest",
      handleDeleteAssignedTickerRequest
    );
  })(),
];

export default watchFunctions;
