import { AccordionDetails, AccordionSummary, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddSymbolDialog from "./AddSymbolDialog";
import { useDispatch, useSelector } from "react-redux";
import { getTickersInWatchListRequest } from "../../redux/reducers/SymbolReducer";
import { RootState } from "../../redux/store";
import { MdLabel } from "react-icons/md";
import { Accordion, Panel } from "baseui/accordion";
import { ChartIntervals, ChartPropsI } from "../Chart/types";

interface ListTickerPropsI {
  id: number;
  name: string;
  description: string;
  isEditable: boolean;
  setCharts: React.Dispatch<React.SetStateAction<ChartPropsI[]>>;
}

interface WatchListI {
  id: number;
  watch_list_id: number;
  symbol: string;
  symbol_type: string;
  symbol_description: string;
}

const ListTickers: React.FC<ListTickerPropsI> = (props) => {
  const { name, description, id, setCharts, isEditable } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const SymbolState = useSelector((state: RootState) => state.Symbol);
  const [tickers, setTickers] = useState<WatchListI[]>([]);

  useEffect(() => {
    switch (SymbolState.status) {
      case "Symbol/getTickersInWatchListRequest":
        break;
      case "Symbol/getTickersInWatchListSuccess":
        if (SymbolState.getTickersInWatchListResponse?.watch_list_id == id) {
          setTickers(SymbolState.getTickersInWatchListResponse?.data);
        }
        break;
      case "Symbol/getTickersInWatchListFailure":
        break;
    }
  }, [SymbolState.status]);

  return (
    <Accordion>
      <Panel
        title={name}
        onClick={(e) => dispatch(getTickersInWatchListRequest(id))}
      >
        <div className="h-36 overflow-y-scroll mb-3">
          {tickers?.map((t) => (
            <div
              className="flex items-center py-2 cursor-pointer"
              onClick={() => {
                let requestObj: ChartPropsI[] = [
                  {
                    ticker: t.symbol,
                    interval: ChartIntervals.OneMinutes,
                    disableTickerChange: true,
                  },
                  {
                    ticker: t.symbol,
                    interval: ChartIntervals.TenMinutes,
                    disableTickerChange: true,
                  },
                  {
                    ticker: t.symbol,
                    interval: ChartIntervals.OneHour,
                    disableTickerChange: true,
                  },
                ];
                setCharts(requestObj);
              }}
            >
              <MdLabel />
              <div className="px-2">
                <h5 className="font-semibold">{t.symbol}</h5>
                <p className="text-xs">{t.symbol_description}</p>
              </div>
            </div>
          ))}
        </div>

        {isEditable && (
          <Button
            variant="outlined"
            color="success"
            onClick={() => setOpen((preState) => !preState)}
          >
            Add Symbol
          </Button>
        )}

        <AddSymbolDialog watchListId={id} open={open} setOpen={setOpen} />
      </Panel>
    </Accordion>
  );
};

export default ListTickers;
