import React, { useState } from "react";
import { MdSpaceDashboard } from "react-icons/md";
import { BsFillCollectionFill } from "react-icons/bs";
import { MdCandlestickChart } from "react-icons/md";
import { LiaRobotSolid } from "react-icons/lia";
import { IoPersonCircle } from "react-icons/io5";
import { LuBrainCircuit } from "react-icons/lu";
import { LuSettings2 } from "react-icons/lu";
import { FaChartLine } from "react-icons/fa";
import { PiEyesFill } from "react-icons/pi";
import { MdOutlineDisplaySettings } from "react-icons/md";
import { Link } from "react-router-dom";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const AdminWrapper: React.FC<{ children: any }> = (props) => {
  const SIZE = 28;
  const COLOR = "#fff";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const AuthState = useSelector((state: RootState) => state.Auth);

  const MenuOptions = [
    {
      name: "Dashboard",
      path: "/",
      icon: <MdSpaceDashboard size={SIZE} color={COLOR} />,
    },
    {
      name: "WatchList",
      path: "/watchlist",
      icon: <PiEyesFill size={24} color={COLOR} />,
    },
    {
      name: "Charts",
      path: "/charts",
      icon: <FaChartLine size={SIZE} color={COLOR} />,
    },
    {
      name: "Indicators",
      path: "/indicators",
      icon: <MdOutlineDisplaySettings size={SIZE} color={COLOR} />,
    },
    {
      name: "Bots",
      path: "/bots/graphical-editor",
      icon: <LiaRobotSolid size={SIZE} color={COLOR} />,
    },
    {
      name: "Strategies",
      path: "/strategies",
      icon: <LuBrainCircuit size={SIZE} color={COLOR} />,
    },
    {
      name: "Account",
      path: "/account",
      icon: <IoPersonCircle size={SIZE} color={COLOR} />,
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="h-screen w-screen flex">
      <div className="sidebar w-20 p-3">
        <img src={require("../assets/images/ic_tazosdhon.png")} />
        <div className="flex-col justify-center items-center pt-8">
          {MenuOptions.map((m) => (
            <Tooltip title={m.name} placement="right-start">
              <Link
                to={m.path}
                className="text-center flex justify-center py-4"
              >
                {m.icon}
              </Link>
            </Tooltip>
          ))}
        </div>
      </div>
      <div className="w-full relative">
        
        <div className="overflow-y-scroll" style={{ height: "100%" }}>
          {props?.children}
        </div>
      </div>
    </div>
  );
};

export default AdminWrapper;
