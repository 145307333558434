import React, { useEffect, useState } from "react";
import "./Signup.css";
import Input from "../../components/Input";
import MyButton from "../../components/MyButton";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { signupRequest } from "../../redux/reducers/AuthReducer";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import MobileNumberInput from "../../components/MobileNumberInput";

const Signup = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuthState = useSelector((state: RootState) => state.Auth);

  const [isLoading, setLoading] = useState(false);

  const onSubmit = (data: any) => {
    dispatch(signupRequest(data));
  };

  useEffect(() => {
    switch (AuthState.status) {
      case "Auth/signupRequest":
        setLoading(true);
        break;
      case "Auth/signupSuccess":
        toast(AuthState.signupResponse?.message, { type: "success" });
        navigate("/signup/otp");
        setLoading(false);
        break;
      case "Auth/signupFailure":
        setLoading(false);
        handleError(AuthState?.error);
        break;
    }
  }, [AuthState.status]);

  const handleError = (error: any) => {
    if (error?.message) {
      toast(error?.message, { type: "error" });
    } else {
      let obj = Object.values(error);
      obj.forEach((message: any, index) => {
        toast(message?.[0], { type: "error" });
      });
    }
  };

  return (
    <>
      <div className="min-w-screen min-h-screen  flex items-center justify-center ">
        <div className=" text-gray-500 h-screen w-full overflow-hidden ">
          <div className="md:flex w-full">
            {/* left bar start  */}
            <div className="flex justify-center items-center left_bg w-1/2 h-screen py-10 px-10 flex-col">
              <img
                src={require("../../assets/images/ic_login_illustration.png")}
                width={"75%"}
                alt=""
                className="mx-auto"
              />
            </div>
            {/* left bar end  */}

            {/* form start  */}
            <div className="flex flex-col md:w-1/2 justify-center py-4 px-10 items-center bg-white">
              <img
                src={require("../../assets/images/ic_tazosdhon_color.png")}
                alt="logo"
                width={125}
                className="logo mx-auto"
              />
              <div className="form_heading mb-4">
                <h1 className="form_heading my-4">Register Account</h1>
              </div>
              <form
                className="bg-white w-2/3 items-center"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Input
                  {...register("name", { required: true })}
                  icon="fa-solid fa-user"
                  type="text"
                  placeholder={"Your Name"}
                />
                {errors.name && <p>Enter your name</p>}
                <Input
                  icon="fa-solid fa-envelope"
                  type="email"
                  {...register("email", { required: true })}
                  placeholder={"Your Email Address"}
                />
                <div className="flex items-center">
                  <select
                    {...register("country_code")}
                    className="p-2 border-2 rounded-2xl focus:outline-none -mt-4"
                  >
                    <option value={"+91"} selected>
                      India
                    </option>
                    <option value={"+1"}>USA</option>
                  </select>
                  <div className="w-full pl-4">
                    <Input
                      icon="fa-solid fa-mobile"
                      type="number"
                      placeholder={"Your mobile number"}
                      {...register("phone", { required: true })}
                    />
                  </div>
                </div>
                <Input
                  icon="fa-solid fa-lock"
                  type="password"
                  placeholder={"***********"}
                  {...register("password", { required: true })}
                />
                <MyButton name="Submit" />

                <hr className="my-5 w-full border-gray-300" />
                {/* <img
                  src="/images/google.png"
                  width={30}
                  alt=""
                  className="mx-auto mt-6 cursor-pointer"
                /> */}
                <p className="bottom_text text-center mt-5">
                  Already have an account?{" "}
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={() => navigate("/")}
                  >
                    Login
                  </span>
                </p>
              </form>
            </div>
            {/* form end  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
