import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

type OTPComponentProps = {
  title: string;
  value: string;
  sendTo?: string
  onChange: React.Dispatch<React.SetStateAction<string>>;
};
export default function OTPComponent(props: OTPComponentProps) {
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");

  useEffect(() => {
    props.onChange(input1 + input2 + input3 + input4);
  }, [input1, input2, input3, input4]);

  return (
    <div className="mb-4">
      <p className="otp_heading mb-3 text-sm">{props.title} <span className="text-green-500 font-semibold">{props?.sendTo}</span></p>
      <OtpInput
        value={props.value}
        containerStyle={{ justifyContent:"space-between"}}
        onChange={props.onChange}
        numInputs={4}
        renderInput={(props) => (
          <input
            {...props}
            className="!w-14 h-12 text-4xl border border-gray-300 rounded-md text-center focus:outline-none focus:border-blue-500"
          />
        )}
      />
    </div>
  );
}
