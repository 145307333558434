import { configureStore } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import GraphicalReducer from "./reducers/GraphicalReducer";
import BotsReducer from "./reducers/BotsReducer";
import { RootSaga } from "../redux-saga/RootSaga";
import AuthReducer from "./reducers/AuthReducer";
import SymbolReducer from "./reducers/SymbolReducer";
import StrategiesReducer from "./reducers/StrategiesReducer";
import IndicatorReducer from "./reducers/IndicatorReducer";

const sagaMiddleware = createSagaMiddleware();

const middleware = [logger, sagaMiddleware];

export const store = configureStore({
  reducer: {
    Graphical: GraphicalReducer,
    Bots: BotsReducer,
    Auth: AuthReducer,
    Symbol: SymbolReducer,
    Strategies: StrategiesReducer,
    Indicator: IndicatorReducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(...middleware);
  },
});

sagaMiddleware.run(RootSaga);

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
