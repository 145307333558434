import React from "react";
import { IconPropsI } from ".";

const Comparison: React.FC<IconPropsI> = (props) => {
  const { height = 36, width = 36, fill = "#495057" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 75"
      version="1.1"
      x="0px"
      y="0px"
      height={height}
      width={width}
      fill={fill}
    >
      <g stroke="none" stroke-width="1" fill={fill} fill-rule="evenodd">
        <g transform="translate(-480.000000, -1050.000000)" fill={fill}>
          <g transform="translate(480.000000, 1050.000000)">
            <path d="M13,2 C6.935,2 2,6.935 2,13 L2,47 C2,53.065 6.935,58 13,58 L47,58 C53.065,58 58,53.065 58,47 L58,13 C58,6.935 53.065,2 47,2 L13,2 Z M47,60 L13,60 C5.832,60 0,54.168 0,47 L0,13 C0,5.832 5.832,0 13,0 L47,0 C54.168,0 60,5.832 60,13 L60,47 C60,54.168 54.168,60 47,60 L47,60 Z" />
            <path d="M42,29 L30,29 C29.448,29 29,28.553 29,28 C29,27.447 29.448,27 30,27 L42,27 C42.552,27 43,27.447 43,28 C43,28.553 42.552,29 42,29" />
            <path d="M42,33 L30,33 C29.448,33 29,32.553 29,32 C29,31.447 29.448,31 30,31 L42,31 C42.552,31 43,31.447 43,32 C43,32.553 42.552,33 42,33" />
            <path d="M18.0083,40 C17.7723,40 17.5353,39.917 17.3453,39.748 C16.9323,39.382 16.8943,38.749 17.2603,38.336 L24.6623,29.994 L17.2533,21.665 C16.8853,21.252 16.9233,20.62 17.3353,20.253 C17.7473,19.885 18.3803,19.923 18.7473,20.335 L26.7473,29.328 C27.0843,29.707 27.0843,30.277 26.7483,30.657 L18.7573,39.664 C18.5593,39.887 18.2843,40 18.0083,40" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Comparison;
