import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  HeaderI,
  getApi,
  postApi,
  protectedPostApi,
} from "../utils/helpers/api";
import {
  addSymbolInWatchListFailure,
  addSymbolInWatchListSuccess,
  createWatchListFailure,
  createWatchListSuccess,
  getAllSymbolsFailure,
  getAllSymbolsSuccess,
  getScannerBotOutputsFailure,
  getScannerBotOutputsSuccess,
  getTickersInWatchListFailure,
  getTickersInWatchListSuccess,
  getWatchListFailure,
  getWatchListSuccess,
  searchSymbolFailure,
  searchSymbolSuccess,
} from "../redux/reducers/SymbolReducer";

function* handleCreateWatchlistRequest(action: PayloadAction): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      "symbols/watchlist",
      action?.payload,
      headers
    );
    if (response?.data?.statusCode == 201) {
      yield put(createWatchListSuccess(response?.data));
    } else {
      yield put(createWatchListFailure(response?.data));
    }
  } catch (error: any) {
    yield put(createWatchListFailure(error?.response?.data));
  }
}

function* handleGetWatchlistRequest(action: PayloadAction): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(getApi, "symbols/watchlist", headers);
    if (response?.data?.statusCode == 200) {
      yield put(getWatchListSuccess(response?.data));
    } else {
      yield put(getWatchListFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getWatchListFailure(error?.response?.data));
  }
}

function* handleSearchSymbolRequest(action: PayloadAction): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      getApi,
      `symbols/search?symbol=${action.payload}`,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(searchSymbolSuccess(response?.data));
    } else {
      yield put(searchSymbolFailure(response?.data));
    }
  } catch (error: any) {
    yield put(searchSymbolFailure(error?.response?.data));
  }
}

function* handleGetTickersInWatchList(action: PayloadAction): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      getApi,
      `symbols/tickers?watchlist=${action.payload}`,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(getTickersInWatchListSuccess(response?.data));
    } else {
      yield put(getTickersInWatchListFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getTickersInWatchListFailure(error?.response?.data));
  }
}

function* handleGetScannerBotOutputsSuccess(action: PayloadAction): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(getApi, `bots/scanner`, headers);
    if (response?.data?.statusCode == 200) {
      yield put(getScannerBotOutputsSuccess(response?.data));
    } else {
      yield put(getScannerBotOutputsFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getScannerBotOutputsFailure(error?.response?.data));
  }
}

function* handleGetAllSymbolsRequest(action: PayloadAction): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(getApi, `symbols/tickers/all`, headers);
    if (response?.data?.statusCode == 200) {
      yield put(getAllSymbolsSuccess(response?.data));
    } else {
      yield put(getAllSymbolsFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getAllSymbolsFailure(error?.response?.data));
  }
}

function* handleAddSymbolInWatchListRequest(action: PayloadAction): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(protectedPostApi, `symbols/tickers`, action.payload, headers);
    if (response?.data?.statusCode == 200) {
      yield put(addSymbolInWatchListSuccess(response?.data));
    } else {
      yield put(addSymbolInWatchListFailure(response?.data));
    }
  } catch (error: any) {
    yield put(addSymbolInWatchListFailure(error?.response?.data));
  }
}

const watchFunctions = [
  (function* () {
    yield takeLatest(
      "Symbol/createWatchListRequest",
      handleCreateWatchlistRequest
    );
  })(),
  (function* () {
    yield takeLatest("Symbol/getWatchListRequest", handleGetWatchlistRequest);
  })(),
  (function* () {
    yield takeLatest("Symbol/searchSymbolRequest", handleSearchSymbolRequest);
  })(),
  (function* () {
    yield takeLatest(
      "Symbol/getTickersInWatchListRequest",
      handleGetTickersInWatchList
    );
  })(),
  (function* () {
    yield takeLatest(
      "Symbol/getScannerBotOutputsRequest",
      handleGetScannerBotOutputsSuccess
    );
  })(),
  (function* () {
    yield takeLatest("Symbol/getAllSymbolsRequest", handleGetAllSymbolsRequest);
  })(),
  (function* () {
    yield takeLatest(
      "Symbol/addSymbolInWatchListRequest",
      handleAddSymbolInWatchListRequest
    );
  })(),
];

export default watchFunctions;
