import React, { useEffect, useState } from "react";
import "./Login.css";
import Input from "../../components/Input";
import MyButton from "../../components/MyButton";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../redux/reducers/AuthReducer";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const AuthState = useSelector((state: RootState) => state.Auth);
  const navigate = useNavigate();

  const [requestPending, setRequestPending] = useState(false);

  useEffect(() => {
    switch (AuthState.status) {
      case "Auth/loginRequest":
        setRequestPending(false);
        break;
      case "Auth/loginSuccess":
        setRequestPending(false);
        toast.success(AuthState.loginResponse?.message);
        navigate("/login/otp");
        break;
      case "Auth/loginFailure":
        setRequestPending(false);
        toast.error(AuthState?.error?.message);
        break;
    }
  }, [AuthState.status]);

  const onSubmit = (data: any) => {
    dispatch(loginRequest(data));
  };
  return (
    <>
      <div className="min-w-screen min-h-screen  flex items-center justify-center ">
        <div className=" text-gray-500 h-screen w-full   overflow-hidden ">
          <div className="md:flex w-full ">
            {/* left bar start  */}
            <div className="flex justify-center items-center left_bg w-1/2 h-screen py-10 px-10 flex-col">
              <img
                src={require("../../assets/images/ic_login_illustration.png")}
                width={"75%"}
                alt=""
                className="mx-auto"
              />
            </div>
            {/* left bar end  */}

            {/* form start  */}
            <div className="flex flex-col md:w-1/2 justify-center py-10 px-10 items-center bg-white">
              {/* <i className="fa-solid fa-arrow-left-long mb-12 text-2xl" /> */}
              <img
                src={require("../../assets/images/ic_tazosdhon_color.png")}
                alt="logo"
                width={125}
                className="logo mx-auto"
              />
              <div className="form_heading my-8">
                <h1 className=" mb-1 form_heading text-center">Login</h1>
                <p className="form_sub mt-4">
                  Enter your email address and password to login
                </p>
              </div>
              <form
                className="bg-white w-2/3 items-center"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Input
                  icon="fa-solid fa-envelope"
                  {...register("email", { required: true })}
                  type="email"
                  placeholder={"Enter your registered email address"}
                />
                <Input
                  icon="fa-solid fa-lock"
                  {...register("password", { required: true })}
                  type="password"
                  placeholder={"********"}
                />
                <MyButton name="Next" isLoading={requestPending} />
                <div className="flex justify-between">
                  <div className="checkbox">
                    {/* <input type="checkbox" />
                    <span className="first_part ms-1 text-sm">I accept the</span>
                    <span className="text-blue-500 cursor-pointer ms-1 text-sm">Terms & Condition</span> */}
                  </div>
                  <span
                    className="text-sm ml-2 mt-3 hover:text-blue-500 cursor-pointer"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password ?
                  </span>
                </div>

                <hr className="my-5 w-full border-gray-300" />

                <p className="bottom_text text-center mt-5">
                  Already have an account?{" "}
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={() => navigate("/signup")}
                  >
                    Register
                  </span>
                </p>
              </form>
            </div>
            {/* form end  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
