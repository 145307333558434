import { createSlice } from "@reduxjs/toolkit";

type AuthInitialStateI = {
  status: string;
  user?: any;
  token?: string | null;
  signupResponse?: any;
  signupOtpVerifyResponse?: any;
  loginResponse?: any;
  loginVerifyResponse?: any;
  updateBankDetailsResponse?: any;
  updateProfileDetailsResponse?: any;
  resendEmailOtpResponse?: any;
  resendPhoneOtpResponse?: any;
  updateEmailResponse?: any;
  updatePhoneResponse?: any;
  forgotPasswordResponse?: any;
  resetPasswordResponse?: any;
  changePasswordResponse?: any;

  users?: any;
  banUserResponse?: any;
  deleteUserResponse?: any;
  error?: any;
  isLoading: boolean;
};
const initialState: AuthInitialStateI = {
  status: "",
  isLoading: true,
};

const AuthReducer = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    getUserFromTokenRequest(state, action) {
      state.status = action.type;
    },
    getUserFromTokenSuccess(state, action) {
      state.status = action.type;
      state.user = action.payload?.user;
      state.token = action.payload?.token;
      state.isLoading = false;
    },
    getUserFromTokenFailure(state, action) {
      state.status = action.type;
      state.isLoading = false;
    },

    signupRequest(state, action) {
      state.status = action.type;
    },
    signupSuccess(state, action) {
      state.status = action.type;
      state.signupResponse = action.payload;
    },
    signupFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    signupOtpVerifyRequest(state, action) {
      state.status = action.type;
    },
    signupOtpVerifySuccess(state, action) {
      state.status = action.type;
      state.signupOtpVerifyResponse = action.payload;
      state.token = action.payload?.token;
    },
    signupOtpVerifyFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    setUser(state, action) {
      state.status = action.type;
      state.user = action.payload;
    },

    loginRequest(state, action) {
      state.status = action.type;
    },
    loginSuccess(state, action) {
      state.status = action.type;
      state.loginResponse = action.payload;
    },
    loginFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    loginVerifyRequest(state, action) {
      state.status = action.type;
    },
    loginVerifySuccess(state, action) {
      state.status = action.type;
      state.loginVerifyResponse = action.payload;
      state.token = action.payload?.token;
    },
    loginVerifyFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    updateProfileDetailsRequest(state, action) {
      state.status = action.type;
    },
    updateProfileDetailsSuccess(state, action) {
      state.status = action.type;
      state.updateProfileDetailsResponse = action.payload;
    },
    updateProfileDetailsFailure(state, action) {
      state.status = action.type;
    },

    updateEmailRequest(state, action) {
      state.status = action.type;
    },
    updateEmailSuccess(state, action) {
      state.status = action.type;
      state.updateEmailResponse = action.payload;
    },
    updateEmailFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    updatePhoneRequest(state, action) {
      state.status = action.type;
    },
    updatePhoneSuccess(state, action) {
      state.status = action.type;
      state.updatePhoneResponse = action.payload;
    },
    updatePhoneFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    updateBankDetailsRequest(state, action) {
      state.status = action.type;
    },
    updateBankDetailsSuccess(state, action) {
      state.status = action.type;
      state.updateBankDetailsResponse = action.payload;
    },
    updateBankDetailsFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    resendEmailOtpRequest(state, action) {
      state.status = action.type;
    },
    resendEmailOtpSuccess(state, action) {
      state.status = action.type;
      state.resendEmailOtpResponse = action.payload;
    },
    resendEmailOtpFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    resendPhoneOtpRequest(state, action) {
      state.status = action.type;
    },
    resendPhoneOtpSuccess(state, action) {
      state.status = action.type;
      state.resendPhoneOtpResponse = action.payload;
    },
    resendPhoneOtpFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    refreshTokenRequest(state, action) {
      state.status = action.type;
    },
    refreshTokenSuccess(state, action) {
      state.status = action.type;
      state.token = action.payload?.token;
    },
    refreshTokenFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    logoutRequest(state, action) {
      state.status = action.type;
    },
    logoutSuccess(state, action) {
      state.status = action.type;
      state.user = null;
      state.token = null;
    },
    logoutFailure(state, action) {
      state.status = action.type;
    },

    getAllUsersRequest(state, action) {
      state.status = action.type;
    },
    getAllUsersSuccess(state, action) {
      state.status = action.type;
      state.users = action.payload;
    },
    getAllUsersFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    banUserRequest(state, action) {
      state.status = action.type;
    },
    banUserSuccess(state, action) {
      state.status = action.type;
      state.banUserResponse = action.payload;
    },
    banUserFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    deleteUserRequest(state, action) {
      state.status = action.type;
    },
    deleteUserSuccess(state, action) {
      state.status = action.type;
      state.deleteUserResponse = action.payload;
    },
    deleteUserFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    forgotPasswordRequest(state, action) {
      state.status = action.type;
    },
    forgotPasswordSuccess(state, action) {
      state.status = action.type;
      state.forgotPasswordResponse = action.payload;
    },
    forgotPasswordFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    resetPasswordRequest(state, action) {
      state.status = action.type;
    },
    resetPasswordSuccess(state, action) {
      state.status = action.type;
      state.resetPasswordResponse = action.payload;
    },
    resetPasswordFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    changePasswordRequest(state, action) {
      state.status = action.type;
    },
    changePasswordSuccess(state, action) {
      state.status = action.type;
      state.changePasswordResponse = action.payload;
    },
    changePasswordFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    clearStatus(state, action) {
      state.status = action.type;
    },
  },
});

export const {
  getUserFromTokenRequest,
  getUserFromTokenSuccess,
  getUserFromTokenFailure,

  signupRequest,
  signupSuccess,
  signupFailure,

  signupOtpVerifyRequest,
  signupOtpVerifySuccess,
  signupOtpVerifyFailure,

  setUser,

  loginRequest,
  loginSuccess,
  loginFailure,

  loginVerifyRequest,
  loginVerifySuccess,
  loginVerifyFailure,

  updateProfileDetailsRequest,
  updateProfileDetailsSuccess,
  updateProfileDetailsFailure,

  updateEmailRequest,
  updateEmailSuccess,
  updateEmailFailure,

  updatePhoneRequest,
  updatePhoneSuccess,
  updatePhoneFailure,

  updateBankDetailsRequest,
  updateBankDetailsSuccess,
  updateBankDetailsFailure,

  resendEmailOtpRequest,
  resendEmailOtpSuccess,
  resendEmailOtpFailure,

  resendPhoneOtpRequest,
  resendPhoneOtpSuccess,
  resendPhoneOtpFailure,

  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenFailure,

  logoutRequest,
  logoutSuccess,
  logoutFailure,

  getAllUsersRequest,
  getAllUsersSuccess,
  getAllUsersFailure,

  banUserRequest,
  banUserSuccess,
  banUserFailure,

  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,

  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,

  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,

  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,

  clearStatus,
} = AuthReducer.actions;

export default AuthReducer.reducer;
