import React from "react";

const Preloader = () => {
  return (
    <div className="preloader h-screen w-screen flex justify-center items-center">
      <img
        src={require("../assets/images/ic_tazosdhon.png")}
        width={200}
        className="mx-auto cursor-pointer duration-500"
        alt=""
      />
    </div>
  );
};

export default Preloader;
