import React, { useEffect, useState } from "react";
import AdminWrapper from "../AdminWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getWatchListRequest } from "../../redux/reducers/SymbolReducer";
import { RootState } from "../../redux/store";
import { LoadingButton } from "@mui/lab";
import AddWatchlist from "./AddWatchlist";
import ListTickers from "./ListTickers";
import { ChartPropsI } from "../Chart/types";
import { webSocket } from "rxjs/webSocket";
import SuperChart from "../Chart/SuperChart";
import Header from "../../components/Header";
import { Button } from "baseui/button";
import { themeStyle } from "../../utils/helpers/theme";

interface WatchListI {
  id: number;
  name: string;
  description: string;
  isEditable: boolean;
}

const WatchList = () => {
  const [watchlist, setWatchlist] = useState<WatchListI[]>([]);
  const SymbolState = useSelector((state: RootState) => state.Symbol);
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [charts, setCharts] = useState<ChartPropsI[]>([]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWatchListRequest({}));
  }, []);

  useEffect(() => {
    switch (SymbolState.status) {
      case "Symbol/getWatchListRequest":
        setIsFetching(true);
        break;
      case "Symbol/getWatchListSuccess":
        setIsFetching(false);
        setWatchlist(SymbolState.getWatchListSuccess?.data);
        break;
      case "Symbol/getWatchListFailure":
        setIsFetching(false);
        break;
    }
  }, [SymbolState.status]);

  const websocketConnection = webSocket({
    url: `${process.env.REACT_APP_WEBSOCKET_BASE_URL}/real-time/market-data`,
  });

  return (
    <AdminWrapper>
      <Header
        header={"All Watchlists"}
        actionButton={
          <Button
            overrides={{
              BaseButton: { style: { ...themeStyle.primaryButton } },
            }}
            isLoading={isCreating}
            onClick={handleClickOpen}
            size="compact"
          >
            Add Watchlist
          </Button>
        }
      />
      <div className="p-4">
        <div className="flex">
          <div className="w-2/12">
            <div>
              {watchlist.map((w) => (
                <ListTickers {...w} setCharts={setCharts} />
              ))}
            </div>
          </div>

          <div className="w-10/12 px-2">
            <h3 className="font-semibold">Ticker: {charts?.[0]?.ticker}</h3>
            <div className="grid grid-cols-3">
              {charts.map((c) => (
                <SuperChart {...c} connection={websocketConnection} />
              ))}
            </div>
          </div>
          <AddWatchlist open={open} setOpen={setOpen} />
        </div>
      </div>
    </AdminWrapper>
  );
};

export default WatchList;
