import React, { useState } from "react";
import AdminWrapper from "../AdminWrapper";
import { ChartIntervals, ChartPropsI } from "./types";
import SuperChart from "./SuperChart";
import { Subject } from "rxjs";
import { webSocket } from "rxjs/webSocket";

const Chart = () => {
  const [charts, setCharts] = useState<ChartPropsI[]>([
    { ticker: "AAPL", interval: ChartIntervals.OneMinutes },
    { ticker: "TSLA", interval: ChartIntervals.OneMinutes },
    { ticker: "AMZN", interval: ChartIntervals.OneMinutes },
    { ticker: "GOOGL", interval: ChartIntervals.OneMinutes },
  ]);

  const websocketConnection = webSocket({
    url: `${process.env.REACT_APP_WEBSOCKET_BASE_URL}/real-time/all-in-one`,
  });

  return (
    <AdminWrapper>
      <div className="grid grid-cols-3 p-4">
        {charts.map((c) => (
          <SuperChart {...c}  connection={websocketConnection} />
        ))}
      </div>
    </AdminWrapper>
  );
};

export default Chart;
