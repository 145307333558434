import React, { useEffect, useState } from "react";
import { Button } from "baseui/button";
import { useForm } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  addSymbolInWatchListRequest,
  getScannerBotOutputsRequest,
  getTickersInWatchListRequest,
  searchSymbolRequest,
} from "../../redux/reducers/SymbolReducer";
import { Select } from "baseui/select";
import { RootState } from "../../redux/store";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import { ButtonGroup } from "@mui/material";
import { useSnackbar } from "baseui/snackbar";
import { Check } from "baseui/icon";

function AddSymbolDialog(props: any) {
  const { open, setOpen, watchListId } = props;
  const { register, handleSubmit, reset, watch } = useForm();
  const dispatch = useDispatch();
  const SymbolState = useSelector((state: RootState) => state.Symbol);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState<any>();

  const [symbol, setSymbol] = useState("");
  const enqueue = useSnackbar();

  useEffect(() => {
    dispatch(getScannerBotOutputsRequest({}));
  }, []);

  React.useEffect(() => {
    switch (SymbolState.status) {
      case "Symbol/searchSymbolRequest":
        setIsSearching(true);
        break;
      case "Symbol/searchSymbolSuccess":
        setIsSearching(false);
        setSearchResults(SymbolState.searchSymbolResponse?.data);
        break;
      case "Symbol/searchSymbolFailure":
        setIsSearching(false);
        break;

      case "Symbol/addSymbolInWatchListSuccess":
        dispatch(getTickersInWatchListRequest(watchListId));
        setSelectedSymbol([]);
        enqueue.enqueue({
          message: "Ticker Added to WatchList",
          startEnhancer: ({ size }) => <Check size={size} />,
        });
        break;
    }
  }, [SymbolState.status]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name == "addYourSymbol") {
        dispatch(searchSymbolRequest(value?.addYourSymbol));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleChange = (event: any) => {
    setSymbol(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = (data: any) => {
    let requestObj = {
      symbol: selectedSymbol[0].id,
      symbol_type: selectedSymbol?.[0]?.label?.split(" - ")[1],
      symbol_description: selectedSymbol?.[0]?.label?.split(" - ")[0],
      watchlist: watchListId,
    };
    console.log(requestObj);
    dispatch(addSymbolInWatchListRequest(requestObj));
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
    >
      {/* <DialogTitle id="alert-dialog-title">Add Symbol </DialogTitle> */}
      <ModalHeader>Add Symbol</ModalHeader>
      <ModalBody>
        <div>
          <Select
            isLoading={isSearching}
            // overrides={{ ControlContainer: { style: { width: "250px" } } }}
            options={searchResults.map((s: any) => {
              return {
                label: `${s.displaySymbol}(${s.description}) - ${s.type}`,
                id: s.symbol,
              };
            })}
            placeholder={"Search Symbol"}
            onInputChange={(e) => {
              let debounce_func = _.debounce((value) => {
                dispatch(searchSymbolRequest(value));
              }, 1000);
              debounce_func(e.target.value);
            }}
            value={selectedSymbol}
            onChange={(value) => {
              setSelectedSymbol([value.option]);
            }}
          />
        </div>
        <span className="text-center">
          <Typography
            variant="h5"
            sx={{ mt: 2, fontWeight: "bold" }}
            className="text-capitalize"
          >
            OR
          </Typography>
        </span>
        <div className="mt-4">
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label" required>
              Select Your Symbol
            </InputLabel>
            {/* <Select
              {...register("symbol", { required: true })}
              required
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              // value={symbol}
              onChange={handleChange}
              label="Select Your Symbol"
            >
              <MenuItem value={"Symbol 1"}>Symbol 1</MenuItem>
              <MenuItem value={"Symbol 2"}>Symbol 2</MenuItem>
            </Select> */}
          </FormControl>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button
            overrides={{ BaseButton: { style: { marginLeft: "12px" } } }}
            onClick={handleSubmit(submit)}
            autoFocus
          >
            Submit
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}

export default AddSymbolDialog;
