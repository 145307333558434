import { Input } from "baseui/input";
import React, { Fragment, useState } from "react";
import { IoTrash } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { Handle, Position } from "reactflow";
import {
  changeNodeData,
  deleteNode,
} from "../../../redux/reducers/GraphicalReducer";
import { getSavedBotRequest } from "../../../redux/reducers/BotsReducer";

const BotNode = (props: any) => {
  const [nodeName, setNodeName] = useState(props?.data?.nodeName);
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Handle position={Position.Right} type="source" />
      <div
        className="shadow-md bg-gray-50 p-2 w-56 border rounded-md"
        onDoubleClick={() => dispatch(getSavedBotRequest(props?.data?.id))}
      >
        <div className="flex justify-center">
          <Input
            size="compact"
            value={nodeName}
            onChange={(e) => {
              setNodeName(e.target.value);
              let requestObj = {
                id: props?.id,
                data: { nodeName: e.target?.value },
              };
              dispatch(changeNodeData(requestObj));
            }}
            overrides={{ Input: { style: { textAlign: "center" } } }}
          />
        </div>

        <div className="flex flex-col py-4 justify-center items-center">
          <h5 className="text-lg font-semibold text-center px-3">
            {props?.data?.name}
          </h5>
          <IoTrash
            size={20}
            className="cursor-pointer text-red-600"
            onClick={() => dispatch(deleteNode(props?.id))}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default BotNode;
