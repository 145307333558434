import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "reactflow/dist/style.css";

import { ReactFlowProvider } from "reactflow";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider, styled } from "baseui";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const engine = new Styletron();

root.render(
  // <React.StrictMode>
  <StyletronProvider value={engine}>
    <BaseProvider theme={LightTheme}>
      <ReactFlowProvider>
        <Provider store={store}>
          <App />
          <ToastContainer />
        </Provider>
      </ReactFlowProvider>
    </BaseProvider>
  </StyletronProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
