export interface ChartPropsI {
  ticker: string;
  interval: ChartIntervals;
  disableTickerChange?: boolean;
}

export enum ChartIntervals {
  OneMinutes = "1 T",
  FiveMinutes = "5 T",
  TenMinutes = "10 T",
  FifteenMinutes = "15 T",
  ThirtyMinutes = "30 T",
  OneHour = "1 H",
  FourHour = "4 H",
  OneDay = "1 D",
  OneWeek = "1 W",
}

export enum EventTypesI {
  HISTORICAL = "HISTORICAL",
  INDICATOR = "INDICATOR",
  BOTS = "BOTS",
  REAL_TIME = "REAL-TIME",
}
