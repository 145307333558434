import { createSlice } from "@reduxjs/toolkit";
import { Edge, Node } from "reactflow";
interface GraphicalStateI {
  status: string;
  nodes: Node[];
  edges: Edge[];
  deleteNodeId: string | null;
  error: {};
}

const initialState: GraphicalStateI = {
  status: "",
  nodes: [],
  edges: [],
  deleteNodeId: null,
  error: {},
};

const GraphicalReducer = createSlice({
  name: "Graphical",
  initialState,
  reducers: {
    setGraphicalNodes(state, action) {
      state.status = action.type;
      state.nodes = action.payload;
    },

    setGraphicalEdges(state, action) {
      state.status = action.type;
      state.edges = action.payload;
    },

    changeNodeData(state, action) {
      state.status = action.type;
      state.nodes = state.nodes?.map((n) => {
        if (n?.id == action?.payload?.id) {
          return {
            ...n,
            data: {
              ...n?.data,
              ...action?.payload?.data,
            },
          };
        }
        return n;
      });
    },

    changeNodePosition(state, action) {
      state.status = action.type;
      state.nodes = state.nodes.map((n) => {
        if (n.id == action.payload?.id) {
          return {
            ...n,
            ...action.payload
          };
        }
        return n;
      });
    },

    changeNodeParams(state, action) {
      state.status = action.payload;
      state.nodes = state.nodes?.map((n) => {
        if (n?.id == action?.payload?.id) {
          return {
            ...n,
            params: { ...n?.data?.params, ...action?.payload?.data },
          };
        }
        return n;
      });
    },

    deleteNode(state, action) {
      state.status = action.type;
      state.deleteNodeId = action.payload;
      state.nodes = state.nodes?.filter((n) => n.id !== action.payload);
    },

    clearStatus(state, action) {
      state.status = action.type;
    },
  },
});

export const {
  changeNodeData,
  changeNodeParams,
  changeNodePosition,
  setGraphicalNodes,
  setGraphicalEdges,
  deleteNode,
  clearStatus,
} = GraphicalReducer.actions;

export default GraphicalReducer.reducer;
