import React, { Fragment } from "react";
import { Handle, Position } from "reactflow";
import { useDispatch } from "react-redux";
import { IoTrash } from "react-icons/io5";
import { TextField } from "@mui/material";
import {
  changeNodeData,
  deleteNode,
} from "../../../../redux/reducers/GraphicalReducer";

const Select = (props: any) => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Handle
        position={Position.Left}
        type="target"
        style={{ top: "24px" }}
        id="input-a"
      />
      <Handle
        position={Position.Left}
        type="target"
        style={{ top: "48px" }}
        id="input-b"
      />
      <Handle
        position={Position.Left}
        type="target"
        style={{ top: "75px" }}
        id="input-c"
      />
      <Handle
        position={Position.Right}
        type="source"
        style={{ top: "48px" }}
        id="input-d"
      />
      <div className="shadow-md bg-gray-50 p-2 w-56 border rounded-md">
        <div className="absolute right-2">
          <IoTrash
            size={20}
            className="cursor-pointer text-red-600"
            onClick={() => dispatch(deleteNode(props?.id))}
          />
        </div>
        <div className="absolute top-4 left-1">
          <p style={{ fontSize: "8px" }}>Inp2 Bool</p>
        </div>
        <div className="absolute top-10 left-1">
          <p style={{ fontSize: "8px" }}>Inp1 Val</p>
        </div>
        <div className="absolute top-16 left-1">
          <p style={{ fontSize: "8px" }}>Inp2 Val</p>
        </div>
        <div className="absolute top-10 right-1">
          <p style={{ fontSize: "8px" }}>Out Val</p>
        </div>

        <h5 className="text-center text-sm">Logical</h5>
        <h5 className="text-lg font-semibold text-center pt-2">
          {props?.data?.name}
        </h5>

        <div className="p-3"></div>

        <TextField
          fullWidth
          inputProps={{ style: { textAlign: "center" } }}
          variant="standard"
          onChange={(e) => {
            let requestObj = {
              id: props?.id,
              data: { nodeName: e.target?.value },
            };
            dispatch(changeNodeData(requestObj));
          }}
          defaultValue={props?.data?.nodeName}
        />
      </div>
    </Fragment>
  );
};

export default Select;
