import React, { useEffect, useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createWatchListRequest,
  getWatchListRequest,
} from "../../redux/reducers/SymbolReducer";
import { RootState } from "../../redux/store";
import { LoadingButton } from "@mui/lab";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { Button } from "baseui/button";
import { themeStyle } from "../../utils/helpers/theme";

function AddWatchlist(props: any) {
  const { open, setOpen } = props;
  const { register, handleSubmit, reset, setValue, watch } = useForm();
  const SymbolState = useSelector((state: RootState) => state.Symbol);
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    switch (SymbolState.status) {
      case "Symbol/createWatchListRequest":
        setIsCreating(true);
        break;
      case "Symbol/createWatchListSuccess":
        setIsCreating(false);
        dispatch(getWatchListRequest({}));
        reset();
        handleClose();
        break;
      case "Symbol/createWatchListFailure":
        setIsCreating(false);
        break;
    }
  }, [SymbolState.status]);

  const onSubmit = (data: any) => {
    dispatch(createWatchListRequest({ ...data, bot_id: 0, isEditable: true }));
  };

  return (
    <div>
      <Modal
        isOpen={open}
        onClose={handleClose}
        size={SIZE.default}
        role={ROLE.alertdialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHeader id="alert-dialog-title">Add Watchlist </ModalHeader>
        <ModalBody>
          <Input
            placeholder="Name"
            value={watch("name")}
            onChange={(value) => setValue("name", value.target.value)}
          />
          <div className="p-2" />
          <Textarea
            placeholder={"Description"}
            value={watch("description")}
            onChange={(value) => setValue("description", value.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            overrides={{
              BaseButton: {
                style: { ...themeStyle.dangerButton, marginRight: "12px" },
              },
            }}
            onClick={handleClose}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            overrides={{
              BaseButton: { style: { ...themeStyle.primaryButton } },
            }}
            isLoading={isCreating}
            onClick={handleSubmit(onSubmit)}
            autoFocus
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddWatchlist;
