import React, { Fragment } from "react";
import { Handle, Position } from "reactflow";
import { useDispatch } from "react-redux";
import { IoTrash } from "react-icons/io5";
import { TextField } from "@mui/material";
import {
  changeNodeData,
  deleteNode,
} from "../../../../redux/reducers/GraphicalReducer";

const Subtraction = (props: any) => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Handle
        type="target"
        position={Position.Left}
        style={{ top: "24px" }}
        id="input-a"
      />
      <Handle
        type="target"
        position={Position.Left}
        style={{ top: "100px" }}
        id="input-b"
      />
      <div className="shadow-md bg-gray-50 p-2 w-64 border rounded-md">
        <div className="absolute top-4 left-1">
          <p style={{ fontSize: "10px" }}>Inp1 Int</p>
        </div>
        <div className="absolute bottom-4 left-1">
          <p style={{ fontSize: "10px" }}>Inp2 Int</p>
        </div>
        <div className="absolute bottom-16 right-1">
          <p style={{ fontSize: "10px" }}>Out Int</p>
        </div>

        <div className="px-12">
          <TextField
            fullWidth
            inputProps={{ style: { textAlign: "center" } }}
            variant="standard"
            size="small"
            onChange={(e) => {
              let requestObj = {
                id: props?.id,
                data: { nodeName: e.target?.value },
              };
              dispatch(changeNodeData(requestObj));
            }}
            defaultValue={props?.data?.nodeName}
          />
        </div>
        <div className="flex px-8 flex-col justify-start items-center">
          <h5 className="text-lg font-semibold pt-2">{props?.data?.name}</h5>
          <IoTrash
            size={20}
            className="cursor-pointer text-red-600"
            onClick={() => dispatch(deleteNode(props?.id))}
          />
        </div>
        <div className="p-2"></div>
      </div>
      <Handle type="source" position={Position.Right} />
    </Fragment>
  );
};

export default Subtraction;
