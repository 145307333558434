import { Modal, ModalBody, ModalFooter, ModalHeader, ROLE } from "baseui/modal";
import React, { useEffect, useState } from "react";
import { BotItemsI } from ".";
import { Select } from "baseui/select";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Button } from "baseui/button";
import { assignWatchListRequest } from "../../../redux/reducers/BotsReducer";
import { assignToStrategyRequest } from "../../../redux/reducers/StrategiesReducer";

interface AssignBotI {
  isOpen: boolean;
  onClose: () => void;
  bot?: any;
  strategy?: any;
  setAssigned: React.Dispatch<React.SetStateAction<string[]>>;
}

const AssignBot: React.FC<AssignBotI> = (props) => {
  const { isOpen, onClose, strategy, setAssigned } = props;
  const SymbolState = useSelector((state: RootState) => state.Symbol);
  const BotsState = useSelector((state: RootState) => state.Bots);

  const [value, setValue] = useState<any>();
  const [value1, setValue1] = useState<any>();
  const [isTicker, setIsTicker] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   switch (BotsState.status) {
  //     case "Bots/assignWatchListSuccess":
  //       if (BotsState.assignWatchListResponse?.bot_id == bot.id) {
  //         setAssigned(BotsState.assignWatchListResponse?.outputs);
  //         onClose();
  //       }
  //       break;
  //   }
  // }, [BotsState.status]);

  const getBot = (id: string) => {
    return SymbolState.getWatchListSuccess?.data?.find(
      (b: BotItemsI) => b.id == id
    );
  };

  return (
    <Modal
      closeable
      role={ROLE.dialog}
      isOpen={isOpen}
      animate
      autoFocus
      onClose={onClose}
    >
      <ModalHeader>Assign Ticker/WatchList</ModalHeader>
      <ModalBody>
        <Select
          options={SymbolState.getWatchListSuccess?.data?.map((s: any) => {
            return {
              id: s.id,
              label: s.name,
            };
          })}
          value={value}
          placeholder={"Please select watchlist"}
          onChange={(params) => {
            setValue(params.value);
            setIsTicker(false);
          }}
        />

        <div className="p-2 justify-center">
          <h3 className="text-center font-semibold">- Or -</h3>
        </div>

        <Select
          options={SymbolState.getAllSymbolsResponse?.data?.map((s: any) => {
            return {
              id: s.id,
              label: s.symbol,
            };
          })}
          value={value1}
          placeholder={"Please select ticker"}
          onChange={(params) => {
            setValue1(params.value);
            setIsTicker(true);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            let requestObj = {
              strategy_id: strategy?.id,
              isTicker: isTicker,
              watchlist: value?.[0].id,
              bot_id: getBot(value?.[0].id)?.bot_id || 0,
              ticker: value1?.[0]?.label,
            };
            dispatch(assignToStrategyRequest(requestObj));
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AssignBot;
