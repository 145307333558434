import React, { useEffect, useState } from "react";
import "./LoginOTP.css";
import MyButton from "../../components/MyButton";
import OTPComponent from "../../components/OTPComponent";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  loginVerifyRequest,
  resendEmailOtpRequest,
  setUser,
} from "../../redux/reducers/AuthReducer";
import { RootState } from "../../redux/store";
import { useCountdown } from "usehooks-ts";
import { useNavigate } from "react-router-dom";

const LoginOTP = () => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const AuthState = useSelector((state: RootState) => state.Auth);
  const navigate = useNavigate();
  const [requestPending, setRequestPending] = useState(false);

  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 60,
      intervalMs: 1000,
    });

  useEffect(() => {
    startCountdown();
  }, []);

  useEffect(() => {
    switch (AuthState.status) {
      case "Auth/loginVerifyRequest":
        setRequestPending(true);
        break;

      case "Auth/loginVerifySuccess":
        setRequestPending(false);
        toast.success(AuthState.loginVerifyResponse?.message);
        navigate("/");
        dispatch(setUser(AuthState.loginVerifyResponse?.user));
        break;

      case "Auth/loginVerifyFailure":
        setRequestPending(false);
        toast.error(AuthState.error?.message || "Something went wrong!");
        break;

      case "Auth/resendEmailOtpSuccess":
        toast.success(AuthState.resendEmailOtpResponse?.message);
        resetCountdown();
        startCountdown();
        break;
      case "Auth/resendEmailOtpFailure":
        break;
    }
  }, [AuthState.status]);

  const onSubmit = () => {
    if (otp.length === 4) {
      let requestObj = {
        email: AuthState.loginResponse?.email,
        otp,
      };
      dispatch(loginVerifyRequest(requestObj));
    } else {
      toast.error("OTP must be a length of 4 digits.");
    }
  };

  const resendOTP = () => {
    let requestObj = {
      email: AuthState.loginResponse?.email,
    };
    dispatch(resendEmailOtpRequest(requestObj));
  };

  return (
    <>
      <div className="min-w-screen min-h-screen  flex items-center justify-center ">
        <div className=" text-gray-500 h-screen w-full overflow-hidden ">
          <div className="md:flex w-full">
            {/* left bar start  */}
            <div className="left_bg w-1/2 h-screen py-10 px-10 flex items-center justify-center flex-col">
              <img
                src={require("../../assets/images/ic_login_illustration.png")}
                alt=""
                width={"75%"}
                className="mx-auto"
              />
            </div>
            {/* left bar end  */}

            {/* form start  */}
            <div className="flex flex-col md:w-1/2 justify-center px-10 py-10 items-center bg-white">
              {/* <i className="fa-solid fa-arrow-left-long mb-12 text-2xl" /> */}
              <img
                src={require("../../assets/images/ic_tazosdhon_color.png")}
                alt="logo"
                width={100}
                className="logo mx-auto"
              />
              <div className="form_heading my-10">
                <h1 className="form_heading text-center">Enter OTP</h1>
                <p className="form_sub mt-2">
                  An 4 digit code has been sent to your mail{" "}
                  <span className="text-green-500 font-semibold">
                    {AuthState?.loginResponse?.email}
                  </span>
                </p>
              </div>
              <div className="bg-white w-2/3 px-12 items-center">
                <div className="mail_otp mb-6">
                  <OTPComponent
                    value={otp}
                    onChange={setOtp}
                    title="Enter OTP send to your registered email address."
                  />
                </div>
                {count == 0 ? (
                  <p
                    className="font-semibold text-blue-500 cursor-pointer"
                    onClick={resendOTP}
                  >
                    Resend OTP
                  </p>
                ) : (
                  <p>
                    Resend new OTP in{" "}
                    <span className="text-blue-500 font-semibold">
                      {count} seconds
                    </span>
                  </p>
                )}

                <MyButton
                  name="Submit"
                  onClick={onSubmit}
                  isLoading={requestPending}
                />
              </div>
              <div className="items-center w-2/3">
                <hr className="my-5 w-full border-gray-300" />

                <p className="bottom_text text-center mt-5">
                  Don’t you have an account?{" "}
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={() => navigate("/signup")}
                  >
                    Sign up
                  </span>
                </p>
              </div>
            </div>
            {/* form end  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginOTP;
