import React, { useEffect, useState } from "react";
import "./SignupOTP.css";
import MyButton from "../../components/MyButton";
import OTPComponent from "../../components/OTPComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import {
  resendEmailOtpRequest,
  resendPhoneOtpRequest,
  setUser,
  signupOtpVerifyRequest,
} from "../../redux/reducers/AuthReducer";
import { useNavigate } from "react-router-dom";
import { useCountdown } from "usehooks-ts";

const SignupOTP = () => {
  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const AuthState = useSelector((state: RootState) => state.Auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mobileCount, mobileCountAction] = useCountdown({
    countStart: 60,
    intervalMs: 1000,
  });

  const [emailCount, emailCountAction] = useCountdown({
    countStart: 60,
    intervalMs: 1000,
  });

  useEffect(() => {
    mobileCountAction.startCountdown();
    emailCountAction.startCountdown();
  }, []);

  useEffect(() => {
    switch (AuthState.status) {
      case "Auth/signupOtpVerifyFailure":
        toast.error(AuthState.error?.message);
        break;

      case "Auth/signupOtpVerifySuccess":
        toast.success(AuthState.signupOtpVerifyResponse?.message);
        navigate("/");
        dispatch(setUser(AuthState.signupOtpVerifyResponse?.user));
        break;

      case "Auth/resendEmailOtpSuccess":
        toast.success(AuthState.resendEmailOtpResponse?.message);
        emailCountAction.resetCountdown();
        emailCountAction.startCountdown();
        break;
      case "Auth/resendEmailOtpFailure":
        toast.error(AuthState.error?.message);
        break;

      case "Auth/resendPhoneOtpSuccess":
        toast.success(AuthState.resendPhoneOtpResponse?.message);
        mobileCountAction.resetCountdown();
        mobileCountAction.startCountdown();
        break;
      case "Auth/resendPhoneOtpFailure":
        toast.error(AuthState.error?.message);
        break;
    }
  }, [AuthState.status]);

  const onSubmit = () => {
    if (emailOtp.length === 4 && phoneOtp.length === 4) {
      let requestObj = {
        email: AuthState.signupResponse?.email,
        email_otp: emailOtp,
        phone_otp: phoneOtp,
      };
      dispatch(signupOtpVerifyRequest(requestObj));
    } else {
      toast.error("OTP must be 4 digits in length");
    }
  };

  const onResendEmailOtp = () => {
    let requestObj = {
      email: AuthState.signupResponse?.email,
    };
    dispatch(resendEmailOtpRequest(requestObj));
  };

  const onResendPhoneOtp = () => {
    let requestObj = {
      phone: AuthState.signupResponse?.phone,
      country_code: AuthState.signupResponse?.country_code,
    };
    dispatch(resendPhoneOtpRequest(requestObj));
  };

  return (
    <>
      <div className="min-w-screen min-h-screen  flex items-center justify-center ">
        <div className=" text-gray-500 h-screen w-full overflow-hidden ">
          <div className="md:flex w-full">
            {/* left bar start  */}
            <div className="flex justify-center items-center left_bg w-1/2 h-screen py-10 px-10 flex-col">
              <img
                src={require("../../assets/images/ic_login_illustration.png")}
                width={"75%"}
                alt=""
                className="mx-auto"
              />
            </div>
            {/* left bar end  */}

            {/* form start  */}
            <div className="flex flex-col md:w-1/2 justify-center py-10 px-10 items-center bg-white">
              <img
                src={require("../../assets/images/ic_tazosdhon_color.png")}
                alt="logo"
                width={125}
                className="logo mx-auto"
              />
              <div className="form_heading my-4">
                <h1 className="form_heading">Enter OTP</h1>
              </div>
              <div className="bg-white w-2/3 px-12 items-center">
                <div className="mb-4">
                  <OTPComponent
                    value={emailOtp}
                    onChange={setEmailOtp}
                    sendTo={AuthState.signupResponse?.email}
                    title={`An 4 digit code has been sent to your mail`}
                  />
                  {emailCount == 0 ? (
                    <p
                      className="font-semibold text-blue-500 cursor-pointer"
                      onClick={onResendEmailOtp}
                    >
                      Resend OTP
                    </p>
                  ) : (
                    <p>
                      Resend new OTP in{" "}
                      <span className="text-blue-500 font-semibold">
                        {emailCount} seconds
                      </span>
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <OTPComponent
                    value={phoneOtp}
                    onChange={setPhoneOtp}
                    sendTo={AuthState.signupResponse?.phone}
                    title={`An 4 digit code has been sent to your phone`}
                  />
                  {mobileCount == 0 ? (
                    <p
                      className="font-semibold text-blue-500 cursor-pointer"
                      onClick={onResendPhoneOtp}
                    >
                      Resend OTP
                    </p>
                  ) : (
                    <p>
                      Resend new OTP in{" "}
                      <span className="text-blue-500 font-semibold">
                        {mobileCount} seconds
                      </span>
                    </p>
                  )}
                </div>
                <MyButton name="Submit" onClick={onSubmit} />
                {/* <hr className="my-5 w-full border-gray-300" /> */}
              </div>
            </div>
            {/* form end  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupOTP;
