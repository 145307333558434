import {
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
} from "@mui/material";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Handle, Position } from "reactflow";
import {
  changeNodeData,
  deleteNode,
} from "../../../../redux/reducers/GraphicalReducer";
import { IoTrash } from "react-icons/io5";

const EdgeTrigger = (props: any) => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Handle type="target" position={Position.Left} />
      <div className="shadow-md bg-gray-50 p-2 w-64 border rounded-md">
        <div className="flex justify-center">
          <TextField
            variant="standard"
            defaultValue={props?.data?.nodeName}
            inputProps={{ style: { textAlign: "center" } }}
            style={{ width: "50%" }}
            onChange={(e) => {
              let requestObj = {
                id: props?.id,
                data: { nodeName: e.target?.value },
              };
              dispatch(changeNodeData(requestObj));
            }}
          />
        </div>

        <div className="flex px-8 flex-col justify-start items-center">
          <h5 className="text-lg font-semibold text-center pt-2">
            {props?.data?.name}
          </h5>
          <IoTrash
            size={20}
            className="cursor-pointer text-red-600"
            onClick={() => dispatch(deleteNode(props?.id))}
          />
        </div>

        <div className="flex pb-2">
          <FormControl fullWidth variant="outlined">
            <InputLabel variant="standard">Type</InputLabel>
            <NativeSelect>
              <option value={"RISING"}>Rising</option>
              <option value={"FALLING"}>Falling</option>
            </NativeSelect>
          </FormControl>
        </div>
      </div>
      <Handle type="source" position={Position.Right} />
    </Fragment>
  );
};

export default EdgeTrigger;
