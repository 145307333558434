import React, { Fragment, useEffect, useReducer, useState } from "react";
import { Math } from "../../assets/icons";
import Logic from "../../assets/icons/Logic";
import Comparison from "../../assets/icons/Comparison";
import Common from "../../assets/icons/Common";
import Indicator from "../../assets/icons/Indicator";
import {
  CommonActions,
  ComparisonActions,
  LogicalActions,
  MathActions,
} from "./actions";
import { StatefulTooltip } from "baseui/tooltip";
import { IconButton } from "@mui/material";
import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import { Select } from "baseui/select";
import { BotItemsI } from "../bots/FlowEditor";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBotsRequest } from "../../redux/reducers/BotsReducer";
import { StrategyI } from ".";

interface StrategyControlsI {
  handleOnAddNode: (type: string, name: string, data: any) => void;
  onCompile: () => void;
  onSave: () => void;
  selectedStrategy?: StrategyI;
}

const StrategyControls: React.FC<StrategyControlsI> = (props) => {
  const { handleOnAddNode, onCompile, onSave, selectedStrategy } = props;
  const dispatch = useDispatch();
  const [bots, setBots] = useState<BotItemsI[]>([]);
  const BotsState = useSelector((state: RootState) => state.Bots);

  const [activeSegment, setActiveSegment] = useState(1);
  const [segments, setSegments] = useState([
    {
      id: 1,
      icon: (isActive: boolean) => (
        <Math fill={isActive ? "#d80032" : "#495057"} />
      ),
    },
    {
      id: 2,
      icon: (isActive: boolean) => (
        <Logic fill={isActive ? "#d80032" : "#495057"} />
      ),
    },
    {
      id: 3,
      icon: (isActive: boolean) => (
        <Comparison fill={isActive ? "#d80032" : "#495057"} />
      ),
    },
    {
      id: 4,
      icon: (isActive: boolean) => (
        <Common fill={isActive ? "#d80032" : "#495057"} />
      ),
    },
    {
      id: 5,
      icon: (isActive: boolean) => (
        <Indicator fill={isActive ? "#d80032" : "#495057"} />
      ),
    },
  ]);

  const segmentMap: any = {
    1: MathActions,
    2: LogicalActions,
    3: ComparisonActions,
    4: CommonActions,
  };

  useEffect(() => {
    dispatch(getBotsRequest({}));
  }, []);

  useEffect(() => {
    switch (BotsState.status) {
      case "Bots/getBotsRequest":
        break;
      case "Bots/getBotsSuccess":
        setBots(
          BotsState?.getBotsSuccess?.data
            ?.filter((d: BotItemsI) => d.bot_type !== "SCANNER_BUY")
            ?.filter((d: BotItemsI) => d.bot_type !== "SCANNER_SELL")
            ?.map((b: BotItemsI) => {
              return { ...b, label: b.name };
            })
        );
        break;
    }
  }, [BotsState.status]);

  return (
    <Fragment>
      {selectedStrategy && (
        <div className="absolute top-0 left-6 rounded-lg bg-blue-500 px-4 py-2">
          <p className="text-white">{selectedStrategy?.name}</p>
        </div>
      )}
      <div className="absolute p-1 border rounded-md bg-white shadow-lg top-2 right-2 z-50 flex items-center">
        {activeSegment == 5 ? (
          <div className="w-48">
            <Select
              size="compact"
              onChange={(e) => {
                handleOnAddNode("BOT", "BOT", e.option);
              }}
              placeholder={"Select Bot"}
              options={bots}
            />
          </div>
        ) : (
          segmentMap[activeSegment].map((d: any) => (
            <div className="cursor-pointer px-1 mx-1 border-r">
              <StatefulTooltip content={() => <p>dc</p>} returnFocus autoFocus>
                {d.icon ? (
                  <IconButton
                    onClick={() => handleOnAddNode(d.type, d.name, d.data)}
                    size="small"
                  >
                    {d.icon}
                  </IconButton>
                ) : (
                  <Button
                    onClick={() => handleOnAddNode(d.type, d.name, d.data)}
                  >
                    {d.name}
                  </Button>
                )}
              </StatefulTooltip>
            </div>
          ))
        )}
        <ButtonGroup size="mini" selected={[0, 1]}>
          <Button size="mini" onClick={onCompile}>
            Compile
          </Button>
          <Button size="mini" onClick={onSave}>
            Save
          </Button>
        </ButtonGroup>
      </div>
      <div className="absolute p-1 border rounded-md bg-white shadow-lg top-16 z-50">
        {segments.map((s: any) => (
          <div
            className="cursor-pointer py-1 my-1 border-b"
            onClick={() => setActiveSegment(s.id)}
          >
            {s.icon(activeSegment == s.id)}
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default StrategyControls;
