import axios from "axios";
import { BASE_URL } from "./constants";
export type HeaderI = {
  ContentType: string;
  AccessToken?: string | null;
  Accept: string;
};

export async function getApiWithoutBase(url: string, headers: any) {
  return await axios.get(url, { headers });
}

export async function getApi(url: string, headers: HeaderI) {
  return await axios.get(`${BASE_URL}/${url}`, {
    headers: {
      Accept: headers.Accept,
      "Content-Type": headers.ContentType,
      Authorization: "Bearer " + headers.AccessToken,
    },
  });
}

export async function postApi(url: string, payload: any, headers: HeaderI) {
  return await axios.post(`${BASE_URL}/${url}`, payload, {
    headers: {
      Accept: headers.Accept,
      "Content-Type": headers.ContentType,
    },
  });
}

export async function protectedPostApi(
  url: string,
  payload: any,
  headers: HeaderI
) {
  return await axios.post(`${BASE_URL}/${url}`, payload, {
    headers: {
      Accept: headers.Accept,
      "Content-Type": headers.ContentType,
      Authorization: "Bearer " + headers.AccessToken,
    },
  });
}

export async function deleteApi(url: string, headers: HeaderI) {
  return await axios.delete(`${BASE_URL}/${url}`, {
    headers: {
      Accept: headers.Accept,
      "Content-Type": headers.ContentType,
      Authorization: "Bearer " + headers.AccessToken,
    },
  });
}
