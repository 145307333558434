import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const Output = (props: any) => {
  const BotsState = useSelector((state: RootState) => state.Bots);
  const GraphicalState = useSelector((state: RootState) => state.Graphical);

  const [result, setResult] = useState<any>();
  const [edges, setEdges] = useState<any>();
  const [edge, setEdge] = useState<any>();
  const [node, setNode] = useState<any>();

  useEffect(() => {
    setResult(BotsState.compileBotsResponse?.data);
    let edge = GraphicalState.edges.find((e) => e.target == props?.id);
    if (edge) {
      let node = GraphicalState.nodes.find((e) => e.id == edge?.source);
      setNode(node);
      console.log(node);
    }
    setEdge(edge);
  }, [BotsState, GraphicalState]);

  return (
    <div className="p-4 rounded-md bg-blue-600 text-white text-center">
      <p className="text-center">{node?.data?.nodeName}</p>
      {JSON.stringify(result?.[node?.data?.nodeName])}
    </div>
  );
};

export default Output;
