import { createSlice } from "@reduxjs/toolkit";

interface BotsStateI {
  status: string;
  compileBotsResponse: any;
  createBotsResponse: any;
  getBotsSuccess: any;
  saveBotResponse: any;
  assignWatchListResponse: any;
  getSavedBotResponse: any;
  error: any;
}

const initialState: BotsStateI = {
  status: "",
  compileBotsResponse: {},
  createBotsResponse: {},
  getBotsSuccess: {},
  saveBotResponse: {},
  getSavedBotResponse: {},
  assignWatchListResponse: {},
  error: {},
};

const BotsReducer = createSlice({
  name: "Bots",
  initialState,
  reducers: {
    compileBotsRequest(state, action) {
      state.status = action.type;
    },
    compileBotsSuccess(state, action) {
      state.status = action.type;
      state.compileBotsResponse = action.payload;
    },
    compileBotsFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Create Bots Reducer Actions
     * @param state
     * @param action
     */
    createBotsRequest(state, action) {
      state.status = action.type;
    },
    createBotsSuccess(state, action) {
      state.status = action.type;
      state.createBotsResponse = action.payload;
    },
    createBotsFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Get All Bots Reducer Actions
     * @param state
     * @param action
     */
    getBotsRequest(state, action) {
      state.status = action.type;
    },
    getBotsSuccess(state, action) {
      state.status = action.type;
      state.getBotsSuccess = action.payload;
    },
    getBotsFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Save Bots Reducer Actions
     * @param state
     * @param action
     */
    saveBotRequest(state, action) {
      state.status = action.type;
    },
    saveBotSuccess(state, action) {
      state.status = action.type;
      state.saveBotResponse = action.payload;
    },
    saveBotFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    getSavedBotRequest(state, action) {
      state.status = action.type;
    },
    getSavedBotSuccess(state, action) {
      state.status = action.type;
      state.getSavedBotResponse = action.payload;
    },
    getSavedBotFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Assigned Watch List Reducer Actions
     * @param state
     * @param action
     */
    assignWatchListRequest(state, action) {
      state.status = action.type;
    },
    assignWatchListSuccess(state, action) {
      state.status = action.type;
      state.assignWatchListResponse = action.payload;
    },
    assignWatchListFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },
  },
});

export const {
  compileBotsRequest,
  compileBotsSuccess,
  compileBotsFailure,

  createBotsRequest,
  createBotsSuccess,
  createBotsFailure,

  getBotsRequest,
  getBotsSuccess,
  getBotsFailure,

  saveBotRequest,
  saveBotSuccess,
  saveBotFailure,

  getSavedBotRequest,
  getSavedBotSuccess,
  getSavedBotFailure,

  assignWatchListRequest,
  assignWatchListSuccess,
  assignWatchListFailure
} = BotsReducer.actions;

export default BotsReducer.reducer;
