import React, { useEffect, useState } from "react";
import { ChartPropsI, EventTypesI } from "./types";
import { Subject } from "rxjs";
import { Autocomplete, TextField } from "@mui/material";
import {
  BotOptions,
  IndicatorOptions,
  IntervalOptions,
  IntervalOptionsMap,
  Tickers,
} from "./data";

interface ControlsProps extends ChartPropsI {
  connection: Subject<unknown>;
}

const Controls: React.FC<ControlsProps> = (props) => {
  const { ticker, interval, connection, disableTickerChange } = props;
  const [intervalValue, setIntervalValue] = useState<any>("1Min");

  useEffect(() => {
    let a = Object.entries(IntervalOptionsMap);
    let e = a.find(([c, d]) => d == interval);
    if (e) {
      let requestObj = {
        type: EventTypesI.HISTORICAL,
        ticker,
        interval: e[1],
      };
      connection.next(requestObj)
      setIntervalValue(e[0]);
    }
  }, []);

  const handleIndicatorChange = (
    e: React.BaseSyntheticEvent,
    value: string
  ) => {
    let requestObj = {
      type: EventTypesI.INDICATOR,
      value,
      ticker,
      interval,
    };
    connection.next(requestObj);
  };

  const handleBotsChange = (e: React.BaseSyntheticEvent, value: string) => {
    let requestObj = {
      type: EventTypesI.BOTS,
      value,
      ticker,
      interval,
    };
    connection.next(requestObj);
  };
  return (
    <div className="flex p-2 bg-gray-50  border-b">
      {!disableTickerChange && (
        <Autocomplete
          disablePortal
          value={ticker}
          disableClearable={true}
          id="combo-box-demo"
          sx={{ width: 85 }}
          options={Tickers}
          size="small"
          popupIcon={null}
          renderInput={(params) => (
            <TextField {...params} variant={"standard"} />
          )}
        />
      )}
      <Autocomplete
        disablePortal
        value={intervalValue}
        disableClearable
        options={IntervalOptions}
        size="small"
        popupIcon={null}
        sx={{ width: 90 }}
        onChange={(e, value) => setIntervalValue(value)}
        renderInput={(params) => <TextField {...params} variant={"standard"} />}
      />
      <Autocomplete
        disablePortal
        disableClearable
        options={IndicatorOptions}
        size="small"
        onChange={handleIndicatorChange}
        popupIcon={null}
        sx={{ width: 90 }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Indicator" variant={"standard"} />
        )}
      />
      <Autocomplete
        disablePortal
        disableClearable
        options={BotOptions}
        size="small"
        popupIcon={null}
        onChange={handleBotsChange}
        sx={{ width: 90 }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Bot" variant={"standard"} />
        )}
      />
    </div>
  );
};

export default Controls;
