import { createSlice } from "@reduxjs/toolkit";

interface SymbolStateI {
  status: string;
  error: any;
  createWatchListResponse: any;
  searchSymbolResponse: any;
  getWatchListSuccess: any;
  getScannerBotOutputsResponse: any;
  addSymbolInWatchListResponse: any;
  getAllSymbolsResponse: any;
  getTickersInWatchListResponse: any;
}

const initialState: SymbolStateI = {
  status: "",
  createWatchListResponse: {},
  getWatchListSuccess: {},
  searchSymbolResponse: {},
  getTickersInWatchListResponse: {},
  getScannerBotOutputsResponse: {},
  addSymbolInWatchListResponse: {},
  getAllSymbolsResponse: {},
  error: {},
};

const SymbolReducer = createSlice({
  name: "Symbol",
  initialState,
  reducers: {
    /**
     * Create Watchlist Reducer Actions
     * @param state
     * @param action
     */
    createWatchListRequest(state, action) {
      state.status = action.type;
    },
    createWatchListSuccess(state, action) {
      state.status = action.type;
      state.createWatchListResponse = action.payload;
    },
    createWatchListFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Get Watchlist Reducer Actions
     * @param state
     * @param action
     */
    getWatchListRequest(state, action) {
      state.status = action.type;
    },
    getWatchListSuccess(state, action) {
      state.status = action.type;
      state.getWatchListSuccess = action.payload;
    },
    getWatchListFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Search Symbol Reducer Actions
     * @param state
     * @param action
     */
    searchSymbolRequest(state, action) {
      state.status = action.type;
    },
    searchSymbolSuccess(state, action) {
      state.status = action.type;
      state.searchSymbolResponse = action.payload;
    },
    searchSymbolFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    getTickersInWatchListRequest(state, action) {
      state.status = action.type;
    },
    getTickersInWatchListSuccess(state, action) {
      state.status = action.type;
      state.getTickersInWatchListResponse = action.payload;
    },
    getTickersInWatchListFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Get Scanner Bot Scanner Bot Outputs Reducer Actions
     * @param state
     * @param action
     */
    getScannerBotOutputsRequest(state, action) {
      state.status = action.type;
    },
    getScannerBotOutputsSuccess(state, action) {
      state.status = action.type;
      state.getScannerBotOutputsResponse = action.payload;
    },
    getScannerBotOutputsFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Get All Symbols Reducer Actions
     * @param state
     * @param action
     */
    getAllSymbolsRequest(state, action) {
      state.status = action.type;
    },
    getAllSymbolsSuccess(state, action) {
      state.status = action.type;
      state.getAllSymbolsResponse = action.payload;
    },
    getAllSymbolsFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    addSymbolInWatchListRequest(state, action) {
      state.status = action.type;
    },
    addSymbolInWatchListSuccess(state, action) {
      state.status = action.type;
      state.addSymbolInWatchListResponse = action.payload;
    },
    addSymbolInWatchListFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },
  },
});

export const {
  createWatchListRequest,
  createWatchListSuccess,
  createWatchListFailure,

  getWatchListRequest,
  getWatchListSuccess,
  getWatchListFailure,

  searchSymbolRequest,
  searchSymbolSuccess,
  searchSymbolFailure,

  getTickersInWatchListRequest,
  getTickersInWatchListSuccess,
  getTickersInWatchListFailure,

  getScannerBotOutputsRequest,
  getScannerBotOutputsSuccess,
  getScannerBotOutputsFailure,

  getAllSymbolsRequest,
  getAllSymbolsSuccess,
  getAllSymbolsFailure,

  addSymbolInWatchListRequest,
  addSymbolInWatchListSuccess,
  addSymbolInWatchListFailure
} = SymbolReducer.actions;

export default SymbolReducer.reducer;
