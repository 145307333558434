import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Select } from "baseui/select";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import React, { useState } from "react";
import { Button } from "baseui/button";
import { useDispatch } from "react-redux";
import { SnackbarProvider, useSnackbar, DURATION } from "baseui/snackbar";

import { createIndicatorRequest } from "../../redux/reducers/IndicatorReducer";
import { Check } from "baseui/icon";

interface CreateIndicatorI {
  isOpen: boolean;
  handleClose: () => void;
}

const CreateIndicator: React.FC<CreateIndicatorI> = (props) => {
  const { isOpen, handleClose } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [calculate, setCalculate] = useState([
    { id: "ON_BAR_CLOSE", value: "ON_BAR_CLOSE" },
  ]);
  const [plotOnPrice, setPlotOnPrice] = useState(true);
  const dispatch = useDispatch();
  const { enqueue } = useSnackbar();

  const getSnackMessage = (message: string) => {
    return {
      message,
      startEnhancer: ({ size }: any) => <Check size={size} />,
    };
  };

  const onSubmit = () => {
    if (name == "") {
      enqueue(getSnackMessage("Please enter name"), DURATION.medium);
      return;
    }
    if (description == "") {
      enqueue(getSnackMessage("Please enter description"), DURATION.medium);
      return;
    }
    if (calculate.length == 0) {
      enqueue(getSnackMessage("Please select calculate"), DURATION.medium);
      return;
    }

    let requestObj = {
      name: name,
      description: description,
      isGlobal: false,
      calculate: calculate?.[0].id,
      plot_on_price: plotOnPrice,
    };
    dispatch(createIndicatorRequest(requestObj));
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalHeader>Create Indicator</ModalHeader>
      <ModalBody>
        <FormControl label={"Name"}>
          <Input
            placeholder="Enter Indicator Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl label={"Description"}>
          <Input
            placeholder="Enter Indicator Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormControl>
        <FormControl label={"Calculate"}>
          <Select
            value={calculate}
            options={[
              { label: "ON_BAR_CLOSE", id: "ON_BAR_CLOSE" },
              { label: "ON_PRICE_CHANGE", id: "ON_PRICE_CHANGE" },
            ]}
            onChange={(e: any) => setCalculate(e.value)}
          />
        </FormControl>
        <FormControl label={"Plot on Price"}>
          <Checkbox
            checked={plotOnPrice}
            onChange={(e) => setPlotOnPrice(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            Plot on Price
          </Checkbox>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onSubmit}>Submit</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateIndicator;
