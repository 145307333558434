export const Tickers = ["AAPL", "TSLA", "AMZN", "QQQ", "GOOGL"];

export const IntervalOptions = [
  "1Min",
  "5Min",
  "10Min",
  "15Min",
  "30Min",
  "1Hour",
  "4Hour",
  "1Day",
  "1Week",
];

export const IntervalOptionsMap = {
  "1Min": "1 T",
  "5Min": "5 T",
  "10Min": "10 T",
  "15Min": "15 T",
  "30Min": "30 T",
  "1Hour": "1 H",
  "4Hour": "4 H",
  "1Day": "1 D",
  "1Week": "1 W",
};

export const IndicatorOptions = ["RSI", "EMA", "MACD"];

export const BotOptions = ["bot_1", "bot_2"]
