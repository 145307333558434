import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Panel } from "baseui/accordion";
import ReactFlow, {
  Background,
  BaseEdge,
  Controls,
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  Node,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  getSmoothStepPath,
} from "reactflow";

import AdminWrapper from "../../AdminWrapper";
import Collapse from "../../../components/Collapse";
import FlowEditorControls from "./FlowEditorControls";
import {
  compileBotsRequest,
  getBotsRequest,
  getSavedBotRequest,
  saveBotRequest,
} from "../../../redux/reducers/BotsReducer";
import {
  getAllSymbolsRequest,
  getWatchListRequest,
} from "../../../redux/reducers/SymbolReducer";
import CreateBots from "../CreateBots";
import { useToggle } from "usehooks-ts";
import { IoTrash } from "react-icons/io5";
import {
  Indicator,
  Add,
  Subtraction,
  Multiplication,
  Division,
  Power,
  And,
  Or,
  Not,
  Equal,
  Select,
  LessThan,
  GreaterThan,
  Variable,
  Output,
} from "../editor/Nodes";
import {
  changeNodePosition,
  clearStatus,
  setGraphicalEdges,
  setGraphicalNodes,
} from "../../../redux/reducers/GraphicalReducer";
import { Button } from "baseui/button";
import { ListHeading } from "baseui/list";
import { RootState } from "../../../redux/store";
import BotItem from "./BotItem";
import { toast } from "react-toastify";
import Signal from "../editor/Nodes/Signal";
import Price from "../editor/Nodes/Price";
import EdgeTrigger from "../editor/Nodes/EdgeTrigger";

export interface BotItemsI {
  id: string;
  name: string;
  description: string;
  type: "custom" | "scanner" | "supervisory";
  assigned: string[];
  assigned_ticker: string;
  actions: any;
  bot_type: "BUY" | "SCANNER_BUY" | "SCANNER_SELL" | "SELL";
}

const FlowEditor = () => {
  const [bots, setBots] = useState<BotItemsI[]>([]);
  const dispatch = useDispatch();
  const BotsState = useSelector((state: RootState) => state.Bots);
  const GraphicalState = useSelector((state: RootState) => state.Graphical);
  const [compiledOutput, setCompiledOutput] = useState();
  const [selectedBot, setSelectedBot] = useState<BotItemsI>();

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const [open, toggleOpen, setOpen] = useToggle();

  useEffect(() => {
    dispatch(getBotsRequest({}));
    dispatch(getWatchListRequest({}));
    dispatch(getAllSymbolsRequest({}));
  }, []);

  useEffect(() => {
    switch (BotsState.status) {
      case "Bots/getBotsSuccess":
        setBots(BotsState.getBotsSuccess?.data);
        break;
      case "Bots/compileBotsSuccess":
        toast.success("Compiled Successfully");
        setCompiledOutput(BotsState?.compileBotsResponse?.data);
        break;
      case "Bots/getSavedBotSuccess":
        setEdges(BotsState.getSavedBotResponse?.data?.edges);
        setNodes(BotsState.getSavedBotResponse?.data?.nodes);
        dispatch(setGraphicalEdges(BotsState.getSavedBotResponse?.data?.edges));
        dispatch(setGraphicalNodes(BotsState.getSavedBotResponse?.data?.nodes));
        break;
      case "Bots/getSavedBotFailure":
        setNodes([]);
        setEdges([]);
        break;
      case "Bots/saveBotSuccess":
        toast.success("Bot Saved Successfully");
        break;
    }
  }, [BotsState.status]);

  useEffect(() => {
    switch (GraphicalState.status) {
      case "Graphical/deleteNode":
        setNodes(GraphicalState.nodes);
        dispatch(clearStatus({}));
        break;
    }
  }, [GraphicalState.status]);

  const handleClose = () => {
    setOpen(false);
  };

  const CustomEdge: React.FC<EdgeProps> = (props) => {
    const { sourceX, sourceY, targetX, targetY, id } = props;

    const [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });

    return (
      <>
        <BaseEdge id={id} path={edgePath} />
        <EdgeLabelRenderer>
          <button
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
              pointerEvents: "all",
            }}
            className="nodrag nopan"
            onClick={() => setEdges((edge) => edge.filter((e) => e.id !== id))}
          >
            <IoTrash color="red" />
          </button>
        </EdgeLabelRenderer>
      </>
    );
  };

  const edgeTypes = {
    "custom-edge": CustomEdge,
  };

  const nodeTypes = useMemo(
    () => ({
      INDICATOR: Indicator,
      ADD: Add,
      SUB: Subtraction,
      MULTIPLICATION: Multiplication,
      DIVISION: Division,
      POW: Power,
      AND: And,
      OR: Or,
      NOT: Not,
      EQUAL: Equal,
      SELECT: Select,
      LESS_THAN: LessThan,
      GREATER_THAN: GreaterThan,
      GREATER_THAN_EQUAL: GreaterThan,
      LESS_THAN_EQUAL: LessThan,
      VARIABLE: Variable,
      OUTPUT: Output,
      SIGNAL: Signal,
      PRICE: Price,
      EDGE_TRIGGER: EdgeTrigger,
    }),
    []
  );

  const getNodeCount = (name: string) => {
    return nodes.filter((n) => n.data?.name == name).length;
  };

  const handleOnAddNode = (type: string, name: string, data: any = {}) => {
    setCompiledOutput(undefined);
    setNodes((preState: any) => {
      let newState = [...preState];
      newState.push({
        id: Math.random().toString().substring(2, 5),
        position: { x: 100, y: 100 },
        type,
        data: { name, nodeName: `${name}_${getNodeCount(name) + 1}`, ...data },
        params: data?.params,
      });
      dispatch(setGraphicalNodes(newState));
      return newState;
    });
  };

  const onNodesChange: OnNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect: OnConnect = useCallback(
    (connection) =>
      setEdges((eds) => addEdge({ ...connection, type: "custom-edge" }, eds)),
    [nodes]
  );

  function compilerSort(unsortedNodes: Node[]) {
    let output: Node[] = [
      ...unsortedNodes.filter((n) => n.type == "INDICATOR"),
      ...unsortedNodes.filter((n) => n.type == "VARIABLE"),
      ...unsortedNodes.filter(
        (n) => n.type !== "INDICATOR" && n.type !== "VARIABLE"
      ),
    ];

    return output;
  }

  const onCompile = () => {
    dispatch(setGraphicalEdges(edges));
    let formatedEdges = edges.map((e) => {
      return { source: e.source, target: e.target };
    });
    let formatedNodes = GraphicalState?.nodes?.map((n: any) => {
      return {
        id: n.id,
        type: n.type,
        data: n.data,
        params: n?.params,
        position: { x: n.position.x, y: n.position.y },
      };
    });
    let requestObj: any = {
      edges: formatedEdges,
      nodes: compilerSort(formatedNodes),
    };

    dispatch(compileBotsRequest(requestObj));
  };

  const onSave = () => {
    if (!compiledOutput) {
      toast.error("Please compile your bot before save");
      return;
    }
    let nodeList = GraphicalState?.nodes.filter((n) => n.type == "SIGNAL");
    if (nodeList.length == 0) {
      toast.error("You need to connect a signal in order to save a bot");
      return;
    }

    let edgeList = edges.filter((e) => e.target == nodeList[0].id);
    let outputNode = GraphicalState?.nodes.filter(
      (n) => n.id == edgeList[0].source
    );

    if (outputNode.length == 0) {
      toast.error("Invalid output node");
      return;
    }

    let formatedEdges = edges.map((e) => {
      return { ...e };
    });
    let formatedNodes = GraphicalState?.nodes?.map((n: any, i: number) => {
      return {
        id: n.id,
        type: n.type,
        data: n.data,
        params: n?.params,
        position: nodes[i].position,
        positionAbsolute: nodes[i].positionAbsolute,
      };
    });
    let bot_code: any = {
      edges: formatedEdges,
      nodes: compilerSort(formatedNodes),
      outputNode: outputNode[0],
      outputNodeName: outputNode[0].data?.nodeName,
    };

    let requestObj = {
      bot: selectedBot?.id,
      data: { edges: formatedEdges, nodes: compilerSort(formatedNodes) },
      code: bot_code,
    };
    dispatch(saveBotRequest(requestObj));
  };

  return (
    <AdminWrapper>
      <div className="flex relative pt-6 px-2 h-full">
        <Collapse>
          <CreateBots isOpen={open} onClose={handleClose} />
          <ListHeading
            heading="Bots"
            endEnhancer={() => (
              <Button
                onClick={toggleOpen}
                style={{ alignSelf: "center" }}
                size="mini"
              >
                Create Bot
              </Button>
            )}
            maxLines={1}
          />

          <Accordion>
            <Panel title={"Buy"}>
              {bots
                .filter((b) => b.bot_type == "BUY")
                .map((b) => (
                  <BotItem
                    {...b}
                    onSelect={(bot) => {
                      setSelectedBot(bot);
                      dispatch(getSavedBotRequest(b.id));
                    }}
                  />
                ))}
            </Panel>
            <Panel title={"Sell"}>
              {bots
                .filter((b) => b.bot_type == "SELL")
                .map((b) => (
                  <BotItem
                    {...b}
                    onSelect={(bot) => {
                      setSelectedBot(bot);
                      dispatch(getSavedBotRequest(b.id));
                    }}
                  />
                ))}
            </Panel>
            <Panel title={"Scanner Buy"}>
              {bots
                .filter((b) => b.bot_type == "SCANNER_BUY")
                .map((b) => (
                  <BotItem
                    {...b}
                    onSelect={(bot) => {
                      setSelectedBot(bot);
                      dispatch(getSavedBotRequest(b.id));
                    }}
                  />
                ))}
            </Panel>
            <Panel title={"Scanner Sell"}>
              {bots
                .filter((b) => b.bot_type == "SCANNER_SELL")
                .map((b) => (
                  <BotItem
                    {...b}
                    onSelect={(bot) => {
                      setSelectedBot(bot);
                      dispatch(getSavedBotRequest(b.id));
                    }}
                  />
                ))}
            </Panel>
          </Accordion>
        </Collapse>

        <div className="w-full">
          <div className="px-3 relative h-full w-full">
            <FlowEditorControls
              bot={selectedBot}
              onCompile={onCompile}
              handleOnAddNode={handleOnAddNode}
              onSave={onSave}
            />
            <ReactFlow
              nodes={nodes}
              edges={edges}
              edgeTypes={edgeTypes}
              nodeTypes={nodeTypes}
              onConnect={onConnect}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
            >
              <Background />
              <Controls />
            </ReactFlow>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default FlowEditor;
