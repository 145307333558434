import React, { Fragment, useEffect, useState } from "react";
import { StrategyI } from ".";
import AssignBot from "../bots/FlowEditor/AssignBot";
import { MdAssignment } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  deleteAssignedTickerRequest,
  getStrategiesRequest,
} from "../../redux/reducers/StrategiesReducer";
import { IoMdTrash } from "react-icons/io";
import { toast } from "react-toastify";

interface StrategyItemsI extends StrategyI {
  onSelect: (strategy: StrategyI) => void;
}

const StrategyItem: React.FC<StrategyItemsI> = (props) => {
  const { name, description, tickers, onSelect, ...rest } = props;
  const [assigned, setAssigned] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const dispatch = useDispatch();

  const StrategyState = useSelector((state: RootState) => state.Strategies);

  useEffect(() => {
    switch (StrategyState.status) {
      case "Strategies/assignToStrategySuccess":
        dispatch(getStrategiesRequest({}));
        handleClose();
        toast.success(StrategyState?.assignToStrategyResponse?.message);
        break;
      case "Strategies/deleteAssignedTickerRequest":
        dispatch(getStrategiesRequest({}));
        toast.success(StrategyState?.deleteAssignedTickerResponse?.message);
        break;
    }
  }, [StrategyState.status]);

  return (
    <Fragment>
      <div className="flex flex-row cursor-pointer justify-between text-orange-700">
        <div
          className="text-sm font-semibold mr-4 text-ellipsis cursor-pointer"
          onClick={() => onSelect({ name, description, ...rest, tickers })}
        >
          {name}
        </div>
        <MdAssignment size={16} onClick={() => setIsOpen(true)} />
      </div>
      <div className="pl-4 pt-2">
        {tickers.map((t, _index) => (
          <div className="flex justify-between pb-2">
            <p className="">
              {_index + 1}. {t}
            </p>
            <IoMdTrash
              className="cursor-pointer"
              size={16}
              onClick={() => {
                let requestObj = {
                  id: rest?.id,
                  ticker: t,
                };
                dispatch(deleteAssignedTickerRequest(requestObj));
              }}
            />
          </div>
        ))}
      </div>
      <AssignBot
        isOpen={isOpen}
        onClose={handleClose}
        strategy={props}
        setAssigned={setAssigned}
      />
    </Fragment>
  );
};

export default StrategyItem;
