import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Select } from "baseui/select";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Button } from "baseui/button";
import { BotItemsI } from ".";
import { assignWatchListRequest } from "../../../redux/reducers/BotsReducer";

type AssignWatchListI = {
  isOpen: boolean;
  onClose: () => void;
  bot: BotItemsI;
};
const AssignWatchList: React.FC<AssignWatchListI> = (props) => {
  const { isOpen, onClose, bot } = props;
  const dispatch = useDispatch();
  const SymbolState = useSelector((state: RootState) => state.Symbol);

  const [value, setValue] = useState<any>();

  let onSubmit = () => {
    let requestObj = {
      bot_id: bot.id,
      watchlist: value?.[0]?.id,
    };
    dispatch(assignWatchListRequest(requestObj));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Assign WatchList</ModalHeader>
      <ModalBody>
        <Select
          options={SymbolState.getWatchListSuccess?.data?.map((s: any) => {
            return {
              id: s.id,
              label: s.name,
            };
          })}
          value={value}
          placeholder={"Please select watchlist"}
          onChange={(params) => {
            setValue(params.value);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onSubmit}>Submit</Button>
      </ModalFooter>
    </Modal>
  );
};

export default AssignWatchList;
