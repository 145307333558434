import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createBotsRequest,
  getBotsRequest,
} from "../../redux/reducers/BotsReducer";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";

interface CreateBotsPropsI {
  isOpen: boolean;
  onClose: () => void;
}

const CreateBots: React.FC<CreateBotsPropsI> = (props) => {
  const { isOpen, onClose } = props;
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const BotsState = useSelector((state: RootState) => state.Bots);
  const onSubmit = (data: any) => {
    dispatch(createBotsRequest(data));
  };

  useEffect(() => {
    switch (BotsState.status) {
      case "Bots/createBotsRequest":
        break;
      case "Bots/createBotsSuccess":
        dispatch(getBotsRequest({}));
        toast.success("Bot created successfully");
        onClose();
        reset();
        break;
      case "Bots/createBotsSuccess":
        toast.error(BotsState.error?.message)
        break
    }
  }, [BotsState.status]);

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>Create Bots</DialogTitle>
      <DialogContent>
        <form className="flex flex-col">
          <TextField
            required
            id="outlined-required"
            variant="standard"
            label="Name"
            {...register("name", { required: true })}
          />
          <div className="my-3">
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              multiline
              variant="standard"
              maxRows={4}
              fullWidth
              {...register("description", { required: false })}
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <TextField
              id="outlined-required"
              label="Line Color1"
              type="color"
              variant="standard"
              {...register("line_color1", { required: false })}
            />
            <TextField
              id="outlined-required"
              label="Line Color2"
              type="color"
              variant="standard"
              {...register("line_color2", { required: false })}
            />
            <TextField
              id="outlined-required"
              label="Line Color3"
              type="color"
              variant="standard"
              {...register("line_color3", { required: false })}
            />

            <TextField
              id="outlined-required"
              label="Line Color4"
              type="color"
              variant="standard"
              {...register("line_color4", { required: false })}
            />
            <TextField
              id="outlined-required"
              label="Line Color5"
              type="color"
              variant="standard"
              {...register("line_color5", { required: false })}
            />
            <TextField
              id="outlined-required"
              label="Line Color6"
              type="color"
              variant="standard"
              {...register("line_color6", { required: false })}
            />
          </div>

          <FormControlLabel
            control={<Checkbox />}
            label="calculateOnPriceChange"
            {...register("calculate_on_price_change", { required: false })}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="plotOnGraph"
            //plot_on_graph
            {...register("plot_on_graph", { required: false })}
          />

          <Select {...register("bot_type", { required: true })}>
            <MenuItem value={"BUY"}>BUY</MenuItem>
            <MenuItem value={"SELL"}>SELL</MenuItem>
            <MenuItem value={"SCANNER_BUY"}>SCANNER(BUY)</MenuItem>
            <MenuItem value={"SCANNER_SELL"}>SCANNER(SELL)</MenuItem>
          </Select>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateBots;
