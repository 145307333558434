import React from "react";
import Lottie from "lottie-react";
import animationData from "../assets/animation_lmd3awf9.json";

export default function MyButton(props: {
  name: string;
  onClick?: () => void;
  isLoading?: boolean;
}) {
  if (props.isLoading) {
    return (
      <div className="flex items-center justify-center">
        <div style={{ height: 50 }}>
          <Lottie
            animationData={animationData}
            style={{ height: 50 }}
            loop={true}
          />
        </div>
        <p className="text-blue-500">Processing...</p>
      </div>
    );
  }

  return (
    <button
      type="submit"
      onClick={props?.onClick}
      className="block w-full mt-4 py-3 text-white mb-2 my_btn"
    >
      {props.name}
    </button>
  );
}
