import React, { Fragment, useEffect, useState } from "react";
import { Math } from "../../../assets/icons";
import Logic from "../../../assets/icons/Logic";
import Comparison from "../../../assets/icons/Comparison";
import Common from "../../../assets/icons/Common";
import Indicator from "../../../assets/icons/Indicator";
import {
  CommonActions,
  ComparisonActions,
  IndicatorActions,
  LogicalActions,
  MathActions,
} from "../editor/actions";
import { Button, IconButton } from "@mui/material";
import { StatefulTooltip } from "baseui/tooltip";
import { BotItemsI } from ".";
import { useDispatch, useSelector } from "react-redux";
import { getIndicatorRequest } from "../../../redux/reducers/IndicatorReducer";
import { RootState } from "../../../redux/store";

interface FlowEditorControlI {
  handleOnAddNode: (type: string, name: string, data: any) => void;
  bot?: BotItemsI;
  onCompile: () => void;
  onSave: () => void;
}

const FlowEditorControls: React.FC<FlowEditorControlI> = (props) => {
  const { handleOnAddNode, bot, onCompile, onSave } = props;
  const dispatch = useDispatch();
  const IndicatorState = useSelector((state: RootState) => state.Indicator);
  const [indicators, setIndicators] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getIndicatorRequest({}));
  }, []);

  const formatParams = (params: { key: string; value: string }[]) => {
    let paramsObj = Object.create({});
    for (let p of params) {
      Object.assign(paramsObj, {
        [p.key]: { name: p.key, value: p.value, key: p.key },
      });
    }
    return paramsObj;
  };

  useEffect(() => {
    switch (IndicatorState.status) {
      case "Indicator/getIndicatorSuccess":
        console.log(IndicatorState.getIndicatorSuccess?.allIndicators)
        let indicators = IndicatorState.getIndicatorSuccess?.allIndicators?.map(
          (i: any) => {
            return {
              label: i?.data?.name,
              type: "INDICATOR",
              name: i?.data?.name,
              data: {
                timeframe: "1 T",
                shift: 0,
                calculateOn: "ON_CANDLE_CLOSE",
                params: formatParams(i?.params),
                id: i?.data?.key
              },
              params: formatParams(i?.params),
            };
          }
        );
        setIndicators(indicators);
        console.log(indicators);
        break;
    }
  }, [IndicatorState.status]);

  const [activeSegment, setActiveSegment] = useState(1);
  const segmentMap: any = {
    1: MathActions,
    2: LogicalActions,
    3: ComparisonActions,
    4: CommonActions,
    5: IndicatorActions,
  };
  const [segments, setSegments] = useState([
    {
      id: 1,
      icon: (isActive: boolean) => (
        <Math fill={isActive ? "#d80032" : "#495057"} />
      ),
    },
    {
      id: 2,
      icon: (isActive: boolean) => (
        <Logic fill={isActive ? "#d80032" : "#495057"} />
      ),
    },
    {
      id: 3,
      icon: (isActive: boolean) => (
        <Comparison fill={isActive ? "#d80032" : "#495057"} />
      ),
    },
    {
      id: 4,
      icon: (isActive: boolean) => (
        <Common fill={isActive ? "#d80032" : "#495057"} />
      ),
    },
    {
      id: 5,
      icon: (isActive: boolean) => (
        <Indicator fill={isActive ? "#d80032" : "#495057"} />
      ),
    },
  ]);

  return (
    <Fragment>
      {bot && (
        <div className="p-2 bg-blue-500 text-sm absolute left-6 text-white rounded-lg">
          {bot?.name}
        </div>
      )}
      <div className="absolute p-1 border rounded-md bg-white shadow-lg top-16 z-50">
        {segments.map((s: any) => (
          <div
            className="cursor-pointer py-1 my-1 border-b"
            onClick={() => setActiveSegment(s.id)}
          >
            {s.icon(activeSegment == s.id)}
          </div>
        ))}
      </div>
      <div className="absolute p-1 border rounded-md bg-white shadow-lg top-2 right-2 z-50 flex">
        {activeSegment == 5
          ? indicators.map((d: any) => (
              <div className="cursor-pointer px-1 mx-1 border-r">
                <StatefulTooltip
                  content={() => <p>dc</p>}
                  returnFocus
                  autoFocus
                >
                  {d.icon ? (
                    <IconButton
                      onClick={() => handleOnAddNode(d.type, d.name, d.data)}
                      size="small"
                    >
                      {d.icon}
                    </IconButton>
                  ) : (
                    <Button
                      onClick={() => handleOnAddNode(d.type, d.name, d.data)}
                    >
                      {d.name}
                    </Button>
                  )}
                </StatefulTooltip>
              </div>
            ))
          : segmentMap[activeSegment].map((d: any) => (
              <div className="cursor-pointer px-1 mx-1 border-r">
                <StatefulTooltip
                  content={() => <p>dc</p>}
                  returnFocus
                  autoFocus
                >
                  {d.icon ? (
                    <IconButton
                      onClick={() => handleOnAddNode(d.type, d.name, d.data)}
                      size="small"
                    >
                      {d.icon}
                    </IconButton>
                  ) : (
                    <Button
                      onClick={() => handleOnAddNode(d.type, d.name, d.data)}
                    >
                      {d.name}
                    </Button>
                  )}
                </StatefulTooltip>
              </div>
            ))}

        <Button
          sx={{ marginLeft: "8px", marginRight: "8px" }}
          size="small"
          variant="contained"
          onClick={onCompile}
        >
          Compile
        </Button>
        <Button variant="outlined" size="small" onClick={onSave}>
          Save
        </Button>
      </div>
    </Fragment>
  );
};

export default FlowEditorControls;
