import React, { useEffect, useRef, useState } from "react";
import { ChartPropsI, EventTypesI } from "./types";
import { Subject } from "rxjs";
import {
  ColorType,
  IChartApi,
  ISeriesApi,
  createChart,
} from "lightweight-charts";
import moment from "moment";
import Controls from "./Controls";
import BotChart from "./BotChart";

interface SuperChartProps extends ChartPropsI {
  connection: Subject<unknown>;
}

const SuperChart: React.FC<SuperChartProps> = (props) => {
  const { connection, ticker, interval, disableTickerChange } = props;
  const [data, setData] = useState<any[]>([]);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [bots, setBots] = useState<any[]>([]);

  const chartInstance = useRef<IChartApi>();
  const mainSeries = useRef<ISeriesApi<"Candlestick">>();

  useEffect(() => {
    connection.next({ type: EventTypesI.HISTORICAL, ticker, interval });
  }, [ticker, interval]);

  const handleHistoricalData = (data: any) => {
    setData(
      data?.map((h: any) => {
        return {
          time: new Date(h.t).getTime(),
          high: h.h,
          low: h.l,
          open: h.o,
          close: h.c,
          volume: h.v,
        };
      })
    );
  };

  const handleIndicatorPlot = (indicator: any, data: any) => {};

  const handleBotsPlot = (bot: string, data: any) => {
    let plottingData = data?.map((i: any) => {
      return {
        time: new Date(i?.t).getTime(),
        value: i?.value ? 1 : 0,
      };
    });
    let requestObj = {
      bot,
      data: plottingData,
    };
    setBots((preState) => [...preState, requestObj]);
  };

  const handleRealTimePlot = (data: any) => {
    let updatedPrice: any = {
      time: new Date(data?.t).getTime(),
      open: data?.o,
      close: data?.c,
      high: data?.h,
      low: data?.l,
    };
    mainSeries.current?.update(updatedPrice);
    console.log("Handle Real-Time Data", updatedPrice);
  };

  connection.subscribe({
    next: (v: any) => {
      if (v?.ticker == ticker) {
        if (v?.type == EventTypesI.HISTORICAL) {
          if (v?.interval == interval) {
            handleHistoricalData(v?.data || []);
          }
        } else if (v?.type == EventTypesI.INDICATOR) {
          handleIndicatorPlot(v?.indicator, v?.data);
        } else if (v?.type == EventTypesI.BOTS) {
          handleBotsPlot(v?.bot, v?.data);
        } else if (v?.type == EventTypesI.REAL_TIME) {
          handleRealTimePlot(v?.data);
        }
      }
    },
  });

  useEffect(() => {
    if (chartContainerRef.current) {
      const handleResize = () => {
        chart.applyOptions({ width: chartContainerRef?.current?.clientWidth });
      };
      const chart = createChart(chartContainerRef?.current, {
        layout: {
          background: { type: ColorType.Solid, color: "#fff" },
          textColor: "#000",
        },
        width: chartContainerRef.current.clientWidth,
        height: 300,
        localization: {
          locale: "en-US",
          timeFormatter: (time: any) => {
            return moment(time).format("DD-MM-YYYY HH:mm A");
          },
        },
        timeScale: {
          timeVisible: true,
          tickMarkFormatter: (time: any) => {
            const d = new Date(time * 1000);
            let local =
              Date.UTC(
                d.getFullYear(),
                d.getMonth(),
                d.getDate(),
                d.getHours(),
                d.getMinutes(),
                d.getSeconds(),
                d.getMilliseconds()
              ) / 1000;
            return moment(local).format("hh:mm A");
          },
        },
      });

      chartInstance.current = chart;

      const candlestickSeries = chart.addCandlestickSeries({
        upColor: "#26a69a",
        downColor: "#ef5350",
        borderVisible: false,
        wickUpColor: "#26a69a",
        wickDownColor: "#ef5350",
      });

      candlestickSeries.setData(data);
      mainSeries.current = candlestickSeries;

      return () => {
        window.removeEventListener("resize", handleResize);
        chart.remove();
      };
    }
  }, [data]);

  return (
    <div className="m-2 z-0  border rounded-lg overflow-hidden">
      <Controls
        disableTickerChange={disableTickerChange}
        ticker={ticker}
        interval={interval}
        connection={connection}
      />
      <div className="p-3">
        <div ref={chartContainerRef}></div>
        {bots?.map((b) => (
          <BotChart {...b} />
        ))}
      </div>
    </div>
  );
};

export default SuperChart;
