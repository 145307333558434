import React from "react";
import { Button, FormControl, Input, InputAdornment } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import HttpsIcon from "@mui/icons-material/Https";
import { Mail } from "@mui/icons-material";
const Login = () => {
  const paperStyle = {
    padding: "4rem",
    minHeight: "70vh",
    width: "60vh",
    margin: "20px auto",
  };
  const btnstyle = { margin: "8px 0" };
  return (
    <Grid
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={10} style={paperStyle}>
        <h2 style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
          Login to your account
        </h2>
        <p>Enter your email address and password to login</p>
        <FormControl variant="standard">
          <Input
            id="suffix-adornment"
            placeholder="you@emample.com"
            fullWidth
            required
            style={{ marginTop: "1rem" }}
            startAdornment={
              <InputAdornment position="start">
                <Mail color="info" />
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl variant="standard">
          <Input
            id="suffix-adornment"
            placeholder="At least 8 character"
            fullWidth
            required
            style={{ marginTop: "1rem" }}
            startAdornment={
              <InputAdornment position="start">
                <HttpsIcon color="info" />
              </InputAdornment>
            }
          />
        </FormControl>
        <div className="flex flex-row gap-3 text-xs justify-between">
          <div className="flex flex-row text-xs">
            <FormControlLabel
              required
              control={<Checkbox />}
              label="I accept the"
            />
          </div>

          <Link href="#">Forgot password ?</Link>
        </div>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
        >
          Next
        </Button>
        <hr></hr>

        <div className="flex justify-center items-center mt-5">
          <HttpsIcon color="info" />
        </div>

        <Typography style={{ fontSize: "1rem" }}>
          {" "}
          Do you have an account ?<Link href="#">Sign Up</Link>
        </Typography>
      </Paper>
    </Grid>
  );
};

export default Login;
