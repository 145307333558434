export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const WEBSOCKET_BASE_URL = `${process.env.REACT_APP_WEBSOCKET_BASE_URL}/indicator`;
export const LIVE_FEED_URL = `${process.env.REACT_APP_WEBSOCKET_BASE_URL}/ticker/live`;
export const BOTS_BASE_URL = `${process.env.REACT_APP_WEBSOCKET_BASE_URL}/bots`;

export const POLYGON_URL = process.env.REACT_APP_POLYGON_URL;
export const POLYGON_KEY = process.env.REACT_APP_POLYGON_KEY;

// export const BASE_URL = "http://52.24.197.150:8000";
// export const WEBSOCKET_BASE_URL = `ws://52.24.197.150:8000/indicator`;
// export const LIVE_FEED_URL = `ws://52.24.197.150:8000/ticker/live`;
// export const BOTS_BASE_URL = `ws://52.24.197.150:8000/bots`
