import { PayloadAction } from "@reduxjs/toolkit";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { HeaderI, postApi, protectedPostApi } from "../utils/helpers/api";
import {
  banUserFailure,
  banUserSuccess,
  changePasswordFailure,
  changePasswordSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  getAllUsersFailure,
  getAllUsersSuccess,
  getUserFromTokenFailure,
  getUserFromTokenSuccess,
  loginFailure,
  loginSuccess,
  loginVerifyFailure,
  loginVerifySuccess,
  logoutFailure,
  logoutSuccess,
  refreshTokenFailure,
  refreshTokenSuccess,
  resendEmailOtpFailure,
  resendEmailOtpSuccess,
  resendPhoneOtpFailure,
  resendPhoneOtpSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  signupFailure,
  signupOtpVerifyFailure,
  signupOtpVerifyRequest,
  signupOtpVerifySuccess,
  signupSuccess,
  updateBankDetailsFailure,
  updateBankDetailsSuccess,
  updateEmailFailure,
  updateEmailSuccess,
  updatePhoneFailure,
  updatePhoneSuccess,
  updateProfileDetailsFailure,
  updateProfileDetailsSuccess,
} from "../redux/reducers/AuthReducer";
import { RootState } from "../redux/store";

const getAuthState = (state: RootState) => state.Auth;

function* getUserFromTokenSaga(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };

    let response = yield call(
      protectedPostApi,
      "user/verify",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(getUserFromTokenSuccess(response?.data));
      localStorage.setItem("algotrade_auth_token", response?.data?.token);
    } else {
      yield put(getUserFromTokenFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getUserFromTokenFailure(error?.response?.data));
  }
}

function* signupSaga(action: PayloadAction<any>): any {
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
    };
    let response: any = yield call(
      postApi,
      "user/registration",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(signupSuccess(response?.data));
    } else {
      yield put(signupFailure(response?.data));
    }
  } catch (error: any) {
    yield put(signupFailure(error.response?.data));
  }
}

function* verifySignupOtp(action: PayloadAction<any>): any {
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
    };
    let response: any = yield call(
      postApi,
      "user/registration/verify",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(signupOtpVerifySuccess(response?.data));
      localStorage.setItem("algotrade_auth_token", response?.data?.token);
    } else {
      yield put(signupOtpVerifyFailure(response?.data));
    }
  } catch (error: any) {
    yield put(signupOtpVerifyFailure(error?.response?.data));
  }
}

function* loginRequestSaga(action: PayloadAction<any>): any {
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
    };
    let response: any = yield call(
      postApi,
      "user/login",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(loginSuccess(response?.data));
    } else {
      yield put(loginFailure(response?.data));
    }
  } catch (error: any) {
    yield put(loginFailure(error.response?.data));
  }
}

function* loginVerifySaga(action: PayloadAction<any>): any {
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
    };
    let response: any = yield call(
      postApi,
      "user/login/verify",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(loginVerifySuccess(response?.data));
      localStorage.setItem("algotrade_auth_token", response?.data?.token);
    } else {
      yield put(loginVerifyFailure(response?.data));
    }
  } catch (error: any) {
    yield put(loginVerifyFailure(error?.response?.data));
  }
}

function* refreshTokenSaga(action: PayloadAction<any>): any {
  const authState = yield select(getAuthState);
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: authState.token,
    };
    let response = yield call(
      protectedPostApi,
      "user/refresh",
      action.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      localStorage.setItem("algotrade_auth_token", response?.data?.token);
      yield put(refreshTokenSuccess(response?.data));
    } else {
      yield put(refreshTokenFailure(response?.data));
    }
  } catch (error: any) {
    yield put(refreshTokenFailure(error?.response?.data));
  }
}

function* updateBankDetailsSaga(action: PayloadAction<any>): any {
  const authState = yield select(getAuthState);
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: authState.token,
    };
    let response = yield call(
      protectedPostApi,
      "user/bank",
      action.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(updateBankDetailsSuccess(response?.data));
    } else {
      yield put(updateBankDetailsFailure(response?.data));
    }
  } catch (error: any) {
    yield put(updateBankDetailsFailure(error.response?.data));
  }
}

function* updateProfileDetailsSaga(action: PayloadAction<any>): any {
  const authState = yield select(getAuthState);
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: authState.token,
    };
    let response = yield call(
      protectedPostApi,
      "user/update",
      action.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(updateProfileDetailsSuccess(response?.data));
    } else {
      yield put(updateProfileDetailsFailure(response?.data));
    }
  } catch (error: any) {
    yield put(updateProfileDetailsFailure(error?.response?.data));
  }
}

function* resendEmailSaga(action: PayloadAction<any>): any {
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
    };
    let response = yield call(
      postApi,
      "user/resend/email",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(resendEmailOtpSuccess(response?.data));
    } else {
      yield put(resendEmailOtpFailure(response?.data));
    }
  } catch (error: any) {
    yield put(resendEmailOtpFailure(error?.response?.data));
  }
}

function* resendPhoneSaga(action: PayloadAction<any>): any {
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
    };
    let response = yield call(
      postApi,
      "user/resend/phone",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(resendPhoneOtpSuccess(response?.data));
    } else {
      yield put(resendPhoneOtpFailure(response?.data));
    }
  } catch (error: any) {
    yield put(resendPhoneOtpFailure(error?.request?.data));
  }
}

function* logoutSaga(action: PayloadAction<any>): any {
  try {
    localStorage.removeItem("algotrade_auth_token");
    yield put(logoutSuccess({}));
  } catch (error) {
    yield put(logoutFailure({}));
  }
}

function* updateEmailSaga(action: PayloadAction<any>): any {
  const authState = yield select(getAuthState);
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: authState.token,
    };
    let response = yield call(
      protectedPostApi,
      "user/update/email",
      action.payload,
      headers
    );
    if (response.data?.statusCode === 200) {
      yield put(updateEmailSuccess(response?.data));
    } else {
      yield put(updateEmailFailure(response?.data));
    }
  } catch (error: any) {
    yield put(updateEmailFailure(error.response?.data));
  }
}

function* updatePhoneSaga(action: PayloadAction<any>): any {
  const authState = yield select(getAuthState);
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: authState.token,
    };
    let response = yield call(
      protectedPostApi,
      "user/update/phone",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(updatePhoneSuccess(response?.data));
    } else {
      yield put(updatePhoneFailure(response?.data));
    }
  } catch (error: any) {
    yield put(updatePhoneFailure(error?.response?.data));
  }
}

function* getAllUsersSaga(action: PayloadAction<any>): any {
  const authState = yield select(getAuthState);
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: authState.token,
    };
    let response = yield call(
      protectedPostApi,
      "user/all-users",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(getAllUsersSuccess(response?.data));
    } else {
      yield put(getAllUsersFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getAllUsersFailure(error.response?.data));
  }
}

function* banUserSaga(action: PayloadAction<any>): any {
  const authState = yield select(getAuthState);
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: authState.token,
    };
    let response = yield call(
      protectedPostApi,
      "user/ban-user",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(banUserSuccess(response?.data));
    } else {
      yield put(banUserFailure(response?.data));
    }
  } catch (error: any) {
    yield put(banUserFailure(error?.response?.data));
  }
}

function* deleteUserSaga(action: PayloadAction<any>): any {
  const authState = yield select(getAuthState);
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: authState.token,
    };
    let response = yield call(
      protectedPostApi,
      "user/delete-user",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(deleteUserSuccess(response?.data));
    } else {
      yield put(deleteUserFailure(response?.data));
    }
  } catch (error: any) {
    yield put(deleteUserFailure(error?.response?.data));
  }
}

function* forgotPasswordSaga(action: PayloadAction<any>): any {
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
    };
    let response = yield call(
      postApi,
      "user/forgot-password",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(forgotPasswordSuccess(response?.data));
    } else {
      yield put(forgotPasswordFailure(response?.data));
    }
  } catch (error: any) {
    yield put(forgotPasswordFailure(error.response?.data));
  }
}

function* resetPasswordSaga(action: PayloadAction<any>): any {
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
    };
    let response = yield call(
      postApi,
      "user/reset-password",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(resetPasswordSuccess(response?.data));
    } else {
      yield put(resetPasswordFailure(response?.data));
    }
  } catch (error: any) {
    yield put(resetPasswordFailure(error.response?.data));
  }
}

function* changePasswordSaga(action: PayloadAction<any>): any {
  const authState = yield select(getAuthState);
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: authState.token,
    };
    let response = yield call(
      protectedPostApi,
      "user/change-password",
      action.payload,
      headers
    );
    if (response?.data?.statusCode === 200) {
      yield put(changePasswordSuccess(response?.data));
    } else {
      yield put(changePasswordFailure(response?.data));
    }
  } catch (error: any) {
    yield put(changePasswordFailure(error?.response?.data));
  }
}

const watchFunctions = [
  (function* () {
    yield takeLatest("Auth/signupRequest", signupSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/signupOtpVerifyRequest", verifySignupOtp);
  })(),
  (function* () {
    yield takeLatest("Auth/loginRequest", loginRequestSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/loginVerifyRequest", loginVerifySaga);
  })(),
  (function* () {
    yield takeLatest("Auth/updateBankDetailsRequest", updateBankDetailsSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/resendEmailOtpRequest", resendEmailSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/resendPhoneOtpRequest", resendPhoneSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/getUserFromTokenRequest", getUserFromTokenSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/logoutRequest", logoutSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/refreshTokenRequest", refreshTokenSaga);
  })(),
  (function* () {
    yield takeLatest(
      "Auth/updateProfileDetailsRequest",
      updateProfileDetailsSaga
    );
  })(),
  (function* () {
    yield takeLatest("Auth/updateEmailRequest", updateEmailSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/updatePhoneRequest", updatePhoneSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/getAllUsersRequest", getAllUsersSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/banUserRequest", banUserSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/deleteUserRequest", deleteUserSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/forgotPasswordRequest", forgotPasswordSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/resetPasswordRequest", resetPasswordSaga);
  })(),
  (function* () {
    yield takeLatest("Auth/changePasswordRequest", changePasswordSaga);
  })(),
];

export default watchFunctions;
