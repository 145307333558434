import Lottie from "lottie-react";
import React from "react";
import animationData from "../assets/404_animation.json";
import DashboardWrapper from "./AdminWrapper";

const NotFound = () => {
  return (
    <DashboardWrapper>
      <div className="w-full flex justify-center items-center">
        <div className="w-3/5">
          <Lottie animationData={animationData} loop={true} />
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default NotFound;
