import React, { Fragment } from "react";
import { IoTrash } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  changeNodeData,
  deleteNode,
} from "../../../../redux/reducers/GraphicalReducer";
import { Select } from "baseui/select";
import { NativeSelect } from "@mui/material";
import { Handle, Position } from "reactflow";
import { Input } from "baseui/input";

const Price = (props: any) => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Handle position={Position.Right} type="source" />
      <div className="shadow-md bg-gray-50 p-2 w-64 border rounded-md">
        <div className="flex px-8 flex-col justify-start items-center">
          <h5 className="text-lg font-semibold text-center pt-2">
            {props?.data?.name}
          </h5>
          <IoTrash
            size={20}
            className="cursor-pointer text-red-600"
            onClick={() => dispatch(deleteNode(props?.id))}
          />
        </div>
        <div className="flex justify-center mt-4">
          <Input
            placeholder="Node Name"
            size="compact"
            onChange={(e) => {
              let requestObj = {
                id: props?.id,
                data: { nodeName: e.target?.value },
              };
              dispatch(changeNodeData(requestObj));
            }}
          />
        </div>
        <div className="flex justify-center items-center">
          <NativeSelect
            style={{ width: "90%", marginTop: "12px", marginBottom: "12px" }}
            onChange={(e) => {
              let requestObj = {
                id: props?.id,
                data: { interval: e.target.value },
              };
              dispatch(changeNodeData(requestObj));
            }}
          >
            <option value={"1T"}>1 Min</option>
            <option value={"5T"}>5 Min</option>
            <option value={"10T"}>10 Min</option>
            <option value={"15T"}>15 Min</option>
            <option value={"30T"}>30 Min</option>
            <option value={"1H"}>1 Hour</option>
            <option value={"4H"}>4 Hour</option>
            <option value={"1D"}>1 Day</option>
          </NativeSelect>

          <NativeSelect
            style={{ width: "90%", marginTop: "12px", marginBottom: "12px" }}
            onChange={(e) => {
              let requestObj = {
                id: props?.id,
                data: { priceType: e.target.value },
              };
              dispatch(changeNodeData(requestObj));
            }}
          >
            <option value={"o"}>Open</option>
            <option value={"c"}>CLOSE</option>
            <option value={"h"}>HIGH</option>
            <option value={"l"}>LOW</option>
          </NativeSelect>
        </div>
      </div>
    </Fragment>
  );
};

export default Price;
