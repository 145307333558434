import React from "react";
import { IconPropsI } from ".";

const Logic: React.FC<IconPropsI> = (props) => {
  const { height = 36, width = 36, fill = "#495057" } = props;
  return (
    <svg
      height={height}
      width={width}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 60"
      x="0px"
      y="0px"
    >
      <title>s</title>
      <path d="M46,22H41.93091A7.99648,7.99648,0,0,0,34,15H23v4H15.36108L7,12v7H2v2H7v7l8.36108-7H23v3H18V36H8v2H20V26h3v5H34a7.99648,7.99648,0,0,0,7.93091-7H46ZM9,23.71716V16.28278L13.44,20ZM34,29H25V17h9a6,6,0,0,1,0,12Z" />
    </svg>
  );
};

export default Logic;
