import { ColorType, IChartApi, createChart } from "lightweight-charts";
import moment from "moment";
import React, { useEffect, useRef } from "react";

interface BotChartPropsI {
  bot: string;
  data: any;
}
const BotChart: React.FC<BotChartPropsI> = (props) => {
  const { bot, data } = props;
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<IChartApi>();

  useEffect(() => {
    if (chartContainerRef.current) {
      const handleResize = () => {
        chart.applyOptions({ width: chartContainerRef?.current?.clientWidth });
      };
      const chart = createChart(chartContainerRef?.current, {
        layout: {
          background: { type: ColorType.Solid, color: "#fff" },
          textColor: "#000",
        },
        width: chartContainerRef.current.clientWidth,
        height: 100,
        localization: {
          locale: "en-US",
          timeFormatter: (time: any) => {
            return moment(time).format("DD-MM-YYYY HH:mm A");
          },
        },
        timeScale: {
          timeVisible: true,
          tickMarkFormatter: (time: any) => {
            const d = new Date(time * 1000);
            let local =
              Date.UTC(
                d.getFullYear(),
                d.getMonth(),
                d.getDate(),
                d.getHours(),
                d.getMinutes(),
                d.getSeconds(),
                d.getMilliseconds()
              ) / 1000;
            return moment(local).format("hh:mm A");
          },
        },
      });

      chartInstance.current = chart;

      let lineSeries = chart.addLineSeries({ color: "#2962FF", lineWidth: 1 });
      lineSeries.setData(data);
    }
  }, [data]);

  return <div ref={chartContainerRef}></div>;
};

export default BotChart;
