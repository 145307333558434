import React, { forwardRef } from "react";

interface InputProps extends HTMLInputElement {
  icon: string;
  name: string;
  type: string;
}

const Input = forwardRef(function Input(props: any, ref) {
  return (
    <>
      <div className="flex items-center border-2 py-2 px-3 rounded-xl mb-4">
        <i className={props.icon} />
        <input
          className="pl-2 outline-none border-none form_input w-full"
          {...props}
          ref={ref}
        />
      </div>
    </>
  );
});

export default Input;
