import React from "react";
import { IconPropsI } from ".";

const Common: React.FC<IconPropsI> = (props) => {
  const { height = 36, width = 36, fill = "#495057" } = props;
  return (
    <svg
      height={height}
      width={width}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 128 160"
      x="0px"
      y="0px"
    >
      <path
        fill={fill}
        d="M117,68.26l-26-15a2,2,0,0,0-2,0l-26,15A2,2,0,0,0,62,70v30a2,2,0,0,0,1,1.73l26,15a2,2,0,0,0,2,0l26-15a2,2,0,0,0,1-1.73V70A2,2,0,0,0,117,68.26Zm-27-11,22.46,13L90,82.7,68,70ZM66,73.46,88,86.15v25.41L66,98.86Zm26,38.1V86.18L114,74V98.85Z"
      />
      <path
        fill={fill}
        d="M56,102.25l-16,8.82V86.72l17-10a2,2,0,1,0-2-3.44l-17,10L15.55,70.56,38,57.82l17,8.95a2,2,0,0,0,1.86-3.54l-18-9.46a2,2,0,0,0-1.92,0L11,68.53a2,2,0,0,0-1,1.74V99.73a2,2,0,0,0,1,1.74L37,116.2a2,2,0,0,0,2,0l19-10.46a2,2,0,1,0-1.92-3.5Zm-42-28L36,86.74V111L14,98.56Z"
      />
      <path
        fill={fill}
        d="M38,49a2,2,0,0,0,2-2V28.46L62,41.15V61a2,2,0,0,0,4,0V41.15L88,28.46V47a2,2,0,0,0,4,0V25a2,2,0,0,0-1-1.73l-26-15a2,2,0,0,0-2,0l-26,15A2,2,0,0,0,36,25V47A2,2,0,0,0,38,49ZM64,12.31,86,25,64,37.69,42,25Z"
      />
    </svg>
  );
};

export default Common;
