import { TextField } from "@mui/material";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Handle, Position } from "reactflow";
import {
  changeNodeData,
  deleteNode,
} from "../../../../redux/reducers/GraphicalReducer";
import { IoTrash } from "react-icons/io5";

const Variable = (props: any) => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Handle
        type="source"
        position={Position.Right}
        // style={{ top: "24px" }}
        id="input-a"
      />
      <div className="shadow-md bg-gray-50 p-2 w-64 border rounded-md">
        <div className="flex justify-center">
          <TextField
            variant="standard"
            defaultValue={props?.data?.nodeName}
            inputProps={{ style: { textAlign: "center" } }}
            style={{ width: "50%" }}
            onChange={(e) => {
              let requestObj = {
                id: props?.id,
                data: { nodeName: e.target?.value },
              };
              dispatch(changeNodeData(requestObj));
            }}
          />
        </div>

        <div className="flex px-8 flex-col justify-start items-center">
          <h5 className="text-lg font-semibold text-center pt-2">
            {props?.data?.name}
          </h5>
          <IoTrash
            size={20}
            className="cursor-pointer text-red-600"
            onClick={() => dispatch(deleteNode(props?.id))}
          />
        </div>

        <div className="flex justify-center mt-2">
          <TextField
            variant="standard"
            defaultValue={props?.data?.value}
            inputProps={{ style: { textAlign: "center" } }}
            style={{ width: "50%" }}
            onChange={(e) => {
              let requestObj = {
                id: props?.id,
                data: { value: parseInt(e.target?.value) },
              };
              dispatch(changeNodeData(requestObj));
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Variable;
