import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  HeaderI,
  getApi,
  postApi,
  protectedPostApi,
} from "../utils/helpers/api";
import {
  assignWatchListFailure,
  assignWatchListSuccess,
  compileBotsFailure,
  compileBotsSuccess,
  createBotsFailure,
  createBotsSuccess,
  getBotsFailure,
  getBotsSuccess,
  getSavedBotFailure,
  getSavedBotSuccess,
  saveBotFailure,
  saveBotSuccess,
} from "../redux/reducers/BotsReducer";

function* handleCompileBotsRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      postApi,
      "bots/compile",
      action?.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(compileBotsSuccess(response?.data));
    } else {
      yield put(compileBotsFailure(response?.data));
    }
  } catch (error: any) {
    yield put(compileBotsFailure(error?.response?.data));
  }
}

function* handleCreateBotsRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      "bots/manage",
      action?.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(createBotsSuccess(response?.data));
    } else {
      yield put(createBotsFailure(response?.data));
    }
  } catch (error: any) {
    yield put(createBotsFailure(error?.response?.data));
  }
}

function* handleGetBotsRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");

  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(getApi, "bots/manage", headers);
    console.log(token);
    if (response?.data?.statusCode == 200) {
      yield put(getBotsSuccess(response?.data));
    } else {
      yield put(getBotsFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getBotsFailure(error?.response?.data));
  }
}

function* handleSaveBotsRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");

  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      "bots/save",
      action.payload,
      headers
    );
    console.log(token);
    if (response?.data?.statusCode == 200) {
      yield put(saveBotSuccess(response?.data));
    } else {
      yield put(saveBotFailure(response?.data));
    }
  } catch (error: any) {
    yield put(saveBotFailure(error?.response?.data));
  }
}

function* handleGetSavedBotRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");

  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      getApi,
      `bots/save?bot_id=${action.payload}`,
      headers
    );
    console.log(token);
    if (response?.data?.statusCode == 200) {
      yield put(getSavedBotSuccess(response?.data));
    } else {
      yield put(getSavedBotFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getSavedBotFailure(error?.response?.data));
  }
}

function* handleAssignWatchListRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");

  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      `bots/scanner`,
      action.payload,
      headers
    );
    console.log(token);
    if (response?.data?.statusCode == 200) {
      yield put(assignWatchListSuccess(response?.data));
    } else {
      yield put(assignWatchListFailure(response?.data));
    }
  } catch (error: any) {
    yield put(assignWatchListFailure(error?.response?.data));
  }
}

const watchFunctions = [
  (function* () {
    yield takeLatest("Bots/compileBotsRequest", handleCompileBotsRequest);
  })(),
  (function* () {
    yield takeLatest("Bots/createBotsRequest", handleCreateBotsRequest);
  })(),
  (function* () {
    yield takeLatest("Bots/getBotsRequest", handleGetBotsRequest);
  })(),
  (function* () {
    yield takeLatest("Bots/saveBotRequest", handleSaveBotsRequest);
  })(),
  (function* () {
    yield takeLatest("Bots/getSavedBotRequest", handleGetSavedBotRequest);
  })(),
  (function* () {
    yield takeLatest(
      "Bots/assignWatchListRequest",
      handleAssignWatchListRequest
    );
  })(),
];

export default watchFunctions;
