import {
  Add,
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  DragHandle,
  KeyboardArrowUp,
  Looks3,
  Percent,
  Remove,
  Tv,
} from "@mui/icons-material";
import { GiLogicGateAnd, GiLogicGateNot, GiLogicGateOr } from "react-icons/gi";
import { IoSquare } from "react-icons/io5";
import {
  LiaGreaterThanEqualSolid,
  LiaLessThanEqualSolid,
} from "react-icons/lia";
import { TbCircuitSwitchOpen } from "react-icons/tb";
import Broker from "../../assets/icons/Broker";
import BrokerQuantity from "../../assets/icons/BrokerQuantity";
import BrokerType from "../../assets/icons/BrokerType";
import { IoIosTimer } from "react-icons/io";

export const MathActions = [
  { icon: <Add />, type: "ADD", name: "ADD", data: {} },
  { icon: <Remove />, type: "SUB", name: "SUBTRACTION", data: {} },
  { icon: <Close />, type: "MULTIPLICATION", name: "MULTIPLICATION", data: {} },
  { icon: <Percent />, type: "DIVISION", name: "DIVISION", data: {} },
  { icon: <KeyboardArrowUp />, type: "POW", name: "POWER", data: {} },
];
export const LogicalActions = [
  { icon: <GiLogicGateAnd />, type: "AND", name: "AND", data: {} },
  { icon: <GiLogicGateOr />, type: "OR", name: "OR", data: {} },
  { icon: <GiLogicGateNot />, type: "NOT", name: "NOT", data: {} },
  { icon: <TbCircuitSwitchOpen />, type: "SELECT", name: "SELECT", data: {} },
];

export const ComparisonActions = [
  { icon: <ArrowBackIos />, type: "LESS_THAN", name: "LESS_THAN", data: {} },
  {
    icon: <ArrowForwardIos />,
    type: "GREATER_THAN",
    name: "GREATER_THAN",
    data: {},
  },
  { icon: <DragHandle />, type: "EQUAL", name: "EQUAL", data: {} },
  {
    icon: <LiaGreaterThanEqualSolid />,
    type: "GREATER_THAN_EQUAL",
    name: "GREATER_THAN_EQUAL",
    data: {},
  },
  {
    icon: <LiaLessThanEqualSolid />,
    type: "LESS_THAN_EQUAL",
    name: "LESS_THAN_EQUAL",
    data: {},
  },
];

export const CommonActions = [
  { icon: <Looks3 />, type: "VARIABLE", name: "VARIABLE", data: { value: 50 } },
  { icon: <Broker />, type: "SIGNAL", name: "SIGNAL", data: {} },
  {
    icon: <BrokerQuantity />,
    type: "QUANTITY",
    name: "QUANTITY",
    data: { quantity: 1 },
  },
  {
    icon: <BrokerType />,
    type: "ORDER_TYPE",
    name: "ORDER_TYPE",
    data: { orderType: "market" },
  },
  {
    icon: <IoIosTimer />,
    type: "TIMER",
    name: "TIMER",
    data: { hour: 9, minute: 30 },
  },
  { icon: <Tv />, type: "OUTPUT", name: "OUTPUT", data: {} },
];

export const IndicatorActions = [
  {
    label: "RSI",
    type: "INDICATOR",
    name: "RSI",
    data: {
      timeframe: "1 T",
      shift: 0,
      params: { length: { name: "Length", value: 14, key: "length" } },
    },
    params: { length: { name: "Length", value: 14, key: "length" } },
  },
  {
    label: "EMA",
    type: "INDICATOR",
    name: "EMA",
    data: {
      timeframe: "1 T",
      shift: 0,
      params: { period: { name: "Period", value: 50, key: "period" } },
    },
    params: { period: { name: "Period", value: 50, key: "period" } },
  },
  {
    label: "MACD",
    type: "INDICATOR",
    name: "MACD",
    data: {
      timeframe: "1 T",
      shift: 0,
      params: {
        fastlength: { name: "Fast Length", value: 12, key: "fastlength" },
        slowlength: { name: "Slow Length", value: 26, key: "slowlength" },
        signalsmoothing: {
          name: "Signal Smoothing",
          value: 9,
          key: "signalsmoothing",
        },
      },
    },
    params: {
      fastlength: { name: "Fast Length", value: 12, key: "fastlength" },
      slowlength: { name: "Slow Length", value: 26, key: "slowlength" },
      signalsmoothing: {
        name: "Signal Smoothing",
        value: 9,
        key: "signalsmoothing",
      },
    },
  },
];
