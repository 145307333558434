import { Input } from "baseui/input";
import React, { Fragment } from "react";
import { Handle, Position } from "reactflow";

const Quantity = () => {
  return (
    <Fragment>
      <Handle position={Position.Right} type="source" />
      <div className="shadow-md bg-gray-50 p-2 w-52 h-32 justify-center items-center flex border rounded-md">
        <div className="flex flex-col py-4 justify-center items-center w-full">
          <h5 className="text-lg font-semibold text-center px-3 mb-4">
            Order Quantity
          </h5>
          <Input size="compact" placeholder="Order Quantity" value={10} />
        </div>
      </div>
    </Fragment>
  );
};

export default Quantity;
