import React, { Fragment } from "react";
import BrokerIcon from "../../../assets/icons/Broker";
import { Handle, Position } from "reactflow";

const Broker = (props: any) => {
  return (
    <Fragment>
      <Handle
        id="quantity"
        style={{ position: "absolute", top: "30px" }}
        position={Position.Left}
        type="target"
      />
      <Handle
        id="order_type"
        style={{ position: "absolute", top: "60px" }}
        position={Position.Left}
        type="target"
      />
      <Handle
        id="order_signal"
        style={{ position: "absolute", top: "90px" }}
        position={Position.Left}
        type="target"
      />
      <div className="shadow-md bg-gray-50 p-2 w-48 h-32 justify-end items-center flex border rounded-md">
        <p
          className="absolute text-sm font-semibold"
          style={{ top: "22px", left: "6px" }}
        >
          Quantity
        </p>
        <p
          className="absolute text-sm font-semibold"
          style={{ top: "50px", left: "6px" }}
        >
          Order Type
        </p>
        <p
          className="absolute text-sm font-semibold"
          style={{ top: "80px", left: "6px" }}
        >
          Order Signal
        </p>
        <BrokerIcon height={64} width={64} />
      </div>
    </Fragment>
  );
};

export default Broker;
