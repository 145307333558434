import { PayloadAction } from "@reduxjs/toolkit";
import {
  HeaderI,
  getApi,
  postApi,
  protectedPostApi,
} from "../utils/helpers/api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  compileIndicatorFailure,
  compileIndicatorSuccess,
  createIndicatorFailure,
  createIndicatorSuccess,
  getIndicatorFailure,
  getIndicatorSuccess,
  getSavedIndicatorFailure,
  getSavedIndicatorSuccess,
  saveIndicatorFailure,
  saveIndicatorSuccess,
} from "../redux/reducers/IndicatorReducer";

/**
 * Handle Create Indictor Request
 * @param action
 */
function* handleCreateIndicatorRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      "technical_indicators/manage",
      action.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(createIndicatorSuccess(response?.data));
    } else {
      yield put(createIndicatorFailure(response?.data));
    }
  } catch (error: any) {
    yield put(createIndicatorFailure(error?.response?.data));
  }
}

/**
 * Get Indicator Request
 * @param action
 */
function* handleGetIndicatorRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(getApi, "technical_indicators/manage", headers);
    if (response?.data?.statusCode == 200) {
      yield put(getIndicatorSuccess(response?.data));
    } else {
      yield put(getIndicatorFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getIndicatorFailure(error?.response?.data));
  }
}

function* handleCompileIndicatorRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      "technical_indicators/compile",
      action.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(compileIndicatorSuccess(response?.data));
    } else {
      yield put(compileIndicatorFailure(response?.data));
    }
  } catch (error: any) {
    yield put(compileIndicatorFailure(error?.response?.data));
  }
}

function* handleSaveIndicatorRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      protectedPostApi,
      "technical_indicators/save",
      action.payload,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(saveIndicatorSuccess(response?.data));
    } else {
      yield put(saveIndicatorFailure(response?.data));
    }
  } catch (error: any) {
    yield put(saveIndicatorFailure(error?.response?.data));
  }
}

function* handleGetSavedIndicatorRequest(action: PayloadAction<any>): any {
  let token = localStorage.getItem("algotrade_auth_token");
  try {
    let headers: HeaderI = {
      Accept: "application/json",
      ContentType: "application/json",
      AccessToken: token,
    };
    let response = yield call(
      getApi,
      `technical_indicators/save?indicator_id=${action.payload}`,
      headers
    );
    if (response?.data?.statusCode == 200) {
      yield put(getSavedIndicatorSuccess(response?.data));
    } else {
      yield put(getSavedIndicatorFailure(response?.data));
    }
  } catch (error: any) {
    yield put(getSavedIndicatorFailure(error?.response?.data));
  }
}

const watchFunctions = [
  (function* () {
    yield takeLatest(
      "Indicator/createIndicatorRequest",
      handleCreateIndicatorRequest
    );
  })(),
  (function* () {
    yield takeLatest(
      "Indicator/getIndicatorRequest",
      handleGetIndicatorRequest
    );
  })(),
  (function* () {
    yield takeLatest(
      "Indicator/compileIndicatorRequest",
      handleCompileIndicatorRequest
    );
  })(),
  (function* () {
    yield takeLatest(
      "Indicator/saveIndicatorRequest",
      handleSaveIndicatorRequest
    );
  })(),
  (function* () {
    yield takeLatest(
      "Indicator/getSavedIndicatorRequest",
      handleGetSavedIndicatorRequest
    );
  })(),
];

export default watchFunctions;
