import React, { Fragment } from "react";
import { Handle, Position } from "reactflow";

const Signal = () => {
  return (
    <Fragment>
      <Handle type="target" position={Position.Left} />
      <div className="h-24 w-24 bg-blue-500 rounded-full text-white flex justify-center items-center font-semibold">
        Signal
      </div>
    </Fragment>
  );
};

export default Signal;
