import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Button } from "baseui/button";
import React, { useEffect, useState } from "react";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import { useForm } from "react-hook-form";
import { PLACEMENT, ToasterContainer, toaster } from "baseui/toast";
import { useDispatch, useSelector } from "react-redux";
import {
  createStrategiesRequest,
  getStrategiesRequest,
} from "../../redux/reducers/StrategiesReducer";
import { RootState } from "../../redux/store";

interface AddStrategiesI {
  isOpen: boolean;
  handleClose: () => void;
}
const AddStrategies: React.FC<AddStrategiesI> = (props) => {
  const { isOpen, handleClose } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [strategyType, setStrategyType] = useState<any>([
    { label: "MID-TERM", id: "MID-TERM" },
  ]);

  const dispatch = useDispatch();
  const StrategiesState = useSelector((state: RootState) => state.Strategies);

  useEffect(() => {
    switch (StrategiesState.status) {
      case "Strategies/createStrategiesSuccess":
        handleClose();
        dispatch(getStrategiesRequest({}));
        break;
      case "Strategies/createStrategiesFailure":
        break;
    }
  }, [StrategiesState.status]);

  const onSubmit = () => {
    if (name == "") {
      toaster.negative(<p>Please enter strategies name</p>);
      return;
    }
    if (description == "") {
      toaster.negative(<p>Please enter description name</p>);
      return;
    }
    let requestObj = {
      name,
      description,
      strategy_type: strategyType?.[0].id,
    };
    dispatch(createStrategiesRequest(requestObj));
  };

  return (
    <ToasterContainer placement={PLACEMENT.topRight}>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalHeader>Add Strategies</ModalHeader>
        <ModalBody>
          <FormControl label={() => "Name"}>
            <Input
              placeholder="Enter strategies name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>

          <FormControl label={() => "Description"}>
            <Input
              placeholder="Enter strategies description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
          <FormControl label={() => "Strategies Type"}>
            <Select
              placeholder={"Select Strategy Type"}
              value={strategyType}
              onChange={(e) => setStrategyType(e.value)}
              options={[
                { label: "SHORT-TERM", id: "SHORT-TERM" },
                { label: "MID-TERM", id: "MID-TERM" },
                { label: "LONG-TERM", id: "LONG-TERM" },
                { label: "INTRADAY", id: "INTRADAY" },
              ]}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onSubmit}>Submit</Button>
        </ModalFooter>
      </Modal>
    </ToasterContainer>
  );
};

export default AddStrategies;
