import { FormControl, Input, InputLabel, NativeSelect } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changeNodeData } from "../../../redux/reducers/GraphicalReducer";

const TimeBlock = (props: any) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(
    `${props?.data?.hour}:${props?.data?.minute}`
  );

  return (
    <div className="shadow-md bg-gray-50 p-2 w-52 h-32 justify-center items-center flex border rounded-md">
      <div className="flex flex-col py-4 justify-center items-center w-full">
        <h5 className="text-lg font-semibold text-center px-3 mb-4">
          Trigger Timer
        </h5>
        <div className="w-full flex">
          <Input
            type="time"
            value={time}
            fullWidth
            onChange={(e) => {
              let time = e.target.value;
              setTime(time);
              let requestObj = {
                id: props?.id,
                data: {
                  hour: parseInt(time.split(":")[0]),
                  minute: parseInt(time.split(":")[1]),
                },
              };
              dispatch(changeNodeData(requestObj));
            }}
          />
        </div>
        {/* <div className="flex w-full pb-2">
          <FormControl fullWidth variant="outlined">
            <NativeSelect
              value={hour}
              onChange={(e) => {
                let requestObj = {
                  id: props?.id,
                  data: { hour: parseInt(e.target?.value) },
                };
                setHour(parseInt(e.target?.value));
                dispatch(changeNodeData(requestObj));
              }}
            >
              {Array.from({ length: 7 }, (_, i) => i + 9).map((i) => (
                <option value={i}>{i}</option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl fullWidth variant="standard">
            <NativeSelect
              value={minute}
              onChange={(e) => {
                let requestObj = {
                  id: props?.id,
                  data: { minute: parseInt(e.target?.value) },
                };
                setMinute(parseInt(e.target?.value));
                dispatch(changeNodeData(requestObj));
              }}
            >
              {Array.from({ length: 60 }, (_, i) => i).map((i) => (
                <option value={i}>{i}</option>
              ))}
            </NativeSelect>
          </FormControl>
        </div> */}
      </div>
    </div>
  );
};

export default TimeBlock;
