import { createSlice } from "@reduxjs/toolkit";

interface StrategiesStateI {
  status: string;
  createStrategiesResponse: any;
  getStrategiesResponse: any;
  compileStrategiesResponse: any;
  saveStrategiesResponse: any;
  getSavedStrategiesResponse: any;
  assignToStrategyResponse: any;
  deleteAssignedTickerResponse: any;
  error: any;
}
const initialState: StrategiesStateI = {
  status: "",
  createStrategiesResponse: {},
  getStrategiesResponse: {},
  compileStrategiesResponse: {},
  saveStrategiesResponse: {},
  getSavedStrategiesResponse: {},
  assignToStrategyResponse: {},
  deleteAssignedTickerResponse: {},
  error: {},
};

const StrategiesReducer = createSlice({
  name: "Strategies",
  initialState,
  reducers: {
    createStrategiesRequest(state, action) {
      state.status = action.type;
    },
    createStrategiesSuccess(state, action) {
      state.status = action.type;
      state.createStrategiesResponse = action.payload;
    },
    createStrategiesFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    getStrategiesRequest(state, action) {
      state.status = action.type;
    },
    getStrategiesSuccess(state, action) {
      state.status = action.type;
      state.getStrategiesResponse = action.payload;
    },
    getStrategiesFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Compile Strategies Reducer Actions
     * @param state
     * @param action
     */
    compileStrategiesRequest(state, action) {
      state.status = action.type;
    },
    compileStrategiesSuccess(state, action) {
      state.status = action.type;
      state.compileStrategiesResponse = action.payload;
    },
    compileStrategiesFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    saveStrategiesRequest(state, action) {
      state.status = action.type;
    },
    saveStrategiesSuccess(state, action) {
      state.status = action.type;
      state.saveStrategiesResponse = action.payload;
    },
    saveStrategiesFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    getSavedStrategiesRequest(state, action) {
      state.status = action.type;
    },
    getSavedStrategiesSuccess(state, action) {
      state.status = action.type;
      state.getSavedStrategiesResponse = action.payload;
    },
    getSavedStrategiesFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    assignToStrategyRequest(state, action) {
      state.status = action.type;
    },
    assignToStrategySuccess(state, action) {
      state.status = action.type;
      state.assignToStrategyResponse = action.payload;
    },
    assignToStrategyFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    deleteAssignedTickerRequest(state, action) {
      state.status = action.type;
    },
    deleteAssignedTickerSuccess(state, action) {
      state.status = action.type;
      state.deleteAssignedTickerResponse = action.payload;
    },
    deleteAssignedTickerFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },
  },
});

export const {
  createStrategiesRequest,
  createStrategiesSuccess,
  createStrategiesFailure,

  getStrategiesRequest,
  getStrategiesSuccess,
  getStrategiesFailure,

  compileStrategiesRequest,
  compileStrategiesSuccess,
  compileStrategiesFailure,

  saveStrategiesRequest,
  saveStrategiesSuccess,
  saveStrategiesFailure,

  getSavedStrategiesRequest,
  getSavedStrategiesSuccess,
  getSavedStrategiesFailure,

  assignToStrategyRequest,
  assignToStrategySuccess,
  assignToStrategyFailure,

  deleteAssignedTickerRequest,
  deleteAssignedTickerSuccess,
  deleteAssignedTickerFailure,
} = StrategiesReducer.actions;

export default StrategiesReducer.reducer;
