import React, { useCallback, useEffect, useMemo, useState } from "react";
import AdminWrapper from "../AdminWrapper";
import Collapse from "../../components/Collapse";
import { ListHeading } from "baseui/list";
import { Button } from "baseui/button";
import { useToggle } from "usehooks-ts";
import ReactFlow, {
  Background,
  BaseEdge,
  Controls,
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  Node,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  getSmoothStepPath,
} from "reactflow";
import AddStrategies from "./AddStrategies";
import { useDispatch, useSelector } from "react-redux";
import {
  compileStrategiesRequest,
  getSavedStrategiesRequest,
  getStrategiesRequest,
  saveStrategiesRequest,
} from "../../redux/reducers/StrategiesReducer";
import { Accordion, Panel } from "baseui/accordion";
import { RootState } from "../../redux/store";
import StrategyItem from "./StrategyItem";
import StrategyControls from "./StrategyControls";
import {
  Add,
  And,
  Division,
  Equal,
  GreaterThan,
  Indicator,
  LessThan,
  Multiplication,
  Not,
  Or,
  Output,
  Power,
  Select,
  Subtraction,
  Variable,
} from "../bots/editor/Nodes";
import { IoTrash } from "react-icons/io5";
import { BotNode, Broker, OrderType, Quantity } from "./Nodes";
import {
  clearStatus,
  setGraphicalEdges,
  setGraphicalNodes,
} from "../../redux/reducers/GraphicalReducer";
import { toast } from "react-toastify";
import Signal from "../bots/editor/Nodes/Signal";
import { IoMdArrowBack } from "react-icons/io";
import TimeBlock from "./Nodes/TimeBlock";
import {
  getAllSymbolsRequest,
  getWatchListRequest,
} from "../../redux/reducers/SymbolReducer";

export interface StrategyI {
  name: string;
  description: string;
  id: number;
  strategy_type: string;
  tickers: string[]
}

const Strategies = () => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [open, toggleOpen, setOpen] = useToggle();
  const dispatch = useDispatch();
  const [compiledOutput, setCompiledOutput] = useState();
  const StrategiesState = useSelector((state: RootState) => state.Strategies);
  const GraphicalState = useSelector((state: RootState) => state.Graphical);
  const BotsState = useSelector((state: RootState) => state.Bots);
  const [strategy, setStrategy] = useState<StrategyI[]>([]);
  const [selectedStrategy, setSelectedStrategy] = useState<
    StrategyI | undefined
  >();
  const [isBot, setIsBot] = useState(false);

  useEffect(() => {
    dispatch(getStrategiesRequest({}));
    dispatch(getWatchListRequest({}));
    dispatch(getAllSymbolsRequest({}));
  }, []);

  useEffect(() => {
    switch (StrategiesState.status) {
      case "Strategies/getStrategiesSuccess":
        setStrategy(StrategiesState.getStrategiesResponse?.data);
        break;
      case "Strategies/compileStrategiesSuccess":
        setCompiledOutput(StrategiesState.compileStrategiesResponse);
        toast.success("Compiled successfully");
        break;
      case "Strategies/saveStrategiesSuccess":
        toast.success("Strategies saved successfully");
        break;
      case "Strategies/getSavedStrategiesSuccess":
        setNodes(StrategiesState.getSavedStrategiesResponse?.data?.nodes);
        setEdges(StrategiesState.getSavedStrategiesResponse?.data?.edges);
        dispatch(
          setGraphicalNodes(
            StrategiesState.getSavedStrategiesResponse?.data?.nodes
          )
        );
        dispatch(
          setGraphicalEdges(
            StrategiesState.getSavedStrategiesResponse?.data?.edges
          )
        );
        break;
      case "Strategies/getSavedStrategiesFailure":
        setNodes([]);
        setEdges([]);
        break;
    }
  }, [StrategiesState.status]);

  useEffect(() => {
    switch (BotsState.status) {
      case "Bots/getSavedBotSuccess":
        setEdges(BotsState.getSavedBotResponse?.data?.edges);
        setNodes(BotsState.getSavedBotResponse?.data?.nodes);
        setIsBot(true);
        break;
    }
  }, [BotsState.status]);

  useEffect(() => {
    switch (GraphicalState.status) {
      case "Graphical/deleteNode":
        setNodes(GraphicalState.nodes);
        dispatch(clearStatus({}));
        break;
    }
  }, [GraphicalState.status]);

  const onNodesChange: OnNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect: OnConnect = useCallback(
    (connection) =>
      setEdges((eds) => addEdge({ ...connection, type: "custom-edge" }, eds)),
    [nodes]
  );

  const CustomEdge: React.FC<EdgeProps> = (props) => {
    const { sourceX, sourceY, targetX, targetY, id } = props;

    const [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });

    return (
      <>
        <BaseEdge id={id} path={edgePath} />
        <EdgeLabelRenderer>
          <button
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
              pointerEvents: "all",
            }}
            className="nodrag nopan"
            onClick={() => setEdges((edge) => edge.filter((e) => e.id !== id))}
          >
            <IoTrash color="red" />
          </button>
        </EdgeLabelRenderer>
      </>
    );
  };

  const edgeTypes = {
    "custom-edge": CustomEdge,
  };

  const nodeTypes = useMemo(
    () => ({
      ADD: Add,
      SUB: Subtraction,
      MULTIPLICATION: Multiplication,
      DIVISION: Division,
      POW: Power,
      AND: And,
      OR: Or,
      NOT: Not,
      EQUAL: Equal,
      SELECT: Select,
      LESS_THAN: LessThan,
      GREATER_THAN: GreaterThan,
      GREATER_THAN_EQUAL: GreaterThan,
      LESS_THAN_EQUAL: LessThan,
      VARIABLE: Variable,
      OUTPUT: Output,
      BOT: BotNode,
      SIGNAL: Broker,
      QUANTITY: Quantity,
      ORDER_TYPE: OrderType,
      TIMER: TimeBlock,
    }),
    []
  );

  const botNodeTypes = useMemo(
    () => ({
      INDICATOR: Indicator,
      ADD: Add,
      SUB: Subtraction,
      MULTIPLICATION: Multiplication,
      DIVISION: Division,
      POW: Power,
      AND: And,
      OR: Or,
      NOT: Not,
      EQUAL: Equal,
      SELECT: Select,
      LESS_THAN: LessThan,
      GREATER_THAN: GreaterThan,
      GREATER_THAN_EQUAL: GreaterThan,
      LESS_THAN_EQUAL: LessThan,
      VARIABLE: Variable,
      OUTPUT: Output,
      SIGNAL: Signal,
    }),
    []
  );

  const getNodeCount = (name: string) => {
    return nodes.filter((n) => n.data?.name == name).length;
  };

  const handleOnAddNode = (type: string, name: string, data: any = {}) => {
    // setCompiledOutput(undefined);
    setNodes((preState: any) => {
      let newState = [...preState];
      newState.push({
        id: Math.random().toString().substring(2, 5),
        position: { x: 100, y: 100 },
        type,
        data: { name, nodeName: `${name}_${getNodeCount(name) + 1}`, ...data },
        params: data?.params,
      });
      dispatch(setGraphicalNodes(newState));
      return newState;
    });
  };

  function compilerSort(unsortedNodes: Node[]) {
    let output: Node[] = [
      ...unsortedNodes.filter((n) => n.type == "BOT"),
      ...unsortedNodes.filter((n) => n.type == "VARIABLE"),
      ...unsortedNodes.filter((n) => n.type !== "BOT" && n.type !== "VARIABLE"),
    ];

    return output;
  }

  const onCompile = () => {
    dispatch(setGraphicalEdges(edges));
    let formatedEdges = edges.map((e) => {
      return { source: e.source, target: e.target };
    });
    let formatedNodes = GraphicalState?.nodes?.map((n: any) => {
      return {
        id: n.id,
        type: n.type,
        data: n.data,
        params: n?.params,
        position: { x: n.position.x, y: n.position.y },
      };
    });
    let requestObj: any = {
      edges: formatedEdges,
      nodes: compilerSort(formatedNodes),
    };
    dispatch(compileStrategiesRequest(requestObj));
  };

  const onSave = () => {
    if (!compiledOutput) {
      toast.error("Please compile your strategy before save");
      return;
    }
    if (!selectedStrategy) {
      toast.error("Please select strategies");
      return;
    }
    let nodeList = GraphicalState?.nodes.filter((n) => n.type == "SIGNAL");
    if (nodeList.length == 0) {
      toast.error("You need to connect a signal in order to save a bot");
      return;
    }

    let edgeList = edges
      .filter((e) => e.target == nodeList[0].id)
      .filter((e) => e.targetHandle == "order_signal");
    let outputNode = GraphicalState?.nodes.filter(
      (n) => n.id == edgeList[0].source
    );

    if (outputNode.length == 0) {
      toast.error("Invalid output node");
      return;
    }

    let formatedEdges = edges.map((e) => {
      return { ...e };
    });
    let formatedNodes = GraphicalState?.nodes?.map((n: any, i: number) => {
      return {
        id: n.id,
        type: n.type,
        data: n.data,
        params: n?.params,
        position: nodes[i].position,
        positionAbsolute: nodes[i].positionAbsolute,
      };
    });
    let strategy_code: any = {
      edges: formatedEdges,
      nodes: compilerSort(formatedNodes),
      outputNode: outputNode[0],
      outputNodeName: outputNode[0].data?.nodeName,
    };

    let requestObj = {
      strategy: selectedStrategy?.id,
      data: { edges: formatedEdges, nodes: compilerSort(formatedNodes) },
      code: strategy_code,
    };
    dispatch(saveStrategiesRequest(requestObj));
  };

  const onBackToStrategies = () => {
    setNodes(StrategiesState.getSavedStrategiesResponse?.data?.nodes);
    setEdges(StrategiesState.getSavedStrategiesResponse?.data?.edges);
    setIsBot(false);
  };

  return (
    <AdminWrapper>
      <AddStrategies isOpen={open} handleClose={() => setOpen(false)} />
      <div className="flex relative pt-6 px-2 h-full">
        <Collapse>
          <ListHeading
            heading="Strategies"
            endEnhancer={() => (
              <Button
                onClick={toggleOpen}
                style={{ alignSelf: "center" }}
                size="mini"
              >
                Add Strategies
              </Button>
            )}
            maxLines={1}
          />

          <Accordion>
            <Panel title={"SHORT-TERM"}>
              {strategy
                .filter((s) => s.strategy_type == "SHORT-TERM")
                .map((s) => (
                  <StrategyItem
                    {...s}
                    onSelect={(strategy) => {
                      setSelectedStrategy(strategy);
                      dispatch(getSavedStrategiesRequest(strategy.id));
                    }}
                  />
                ))}
            </Panel>
            <Panel title={"MID-TERM"}>
              {strategy
                .filter((s) => s.strategy_type == "MID-TERM")
                .map((s) => (
                  <StrategyItem
                    {...s}
                    onSelect={(strategy) => {
                      setSelectedStrategy(strategy);
                      dispatch(getSavedStrategiesRequest(strategy.id));
                    }}
                  />
                ))}
            </Panel>
            <Panel title={"LONG-TERM"}>
              {strategy
                .filter((s) => s.strategy_type == "LONG-TERM")
                .map((s) => (
                  <StrategyItem
                    {...s}
                    onSelect={(strategy) => {
                      setSelectedStrategy(strategy);
                      dispatch(getSavedStrategiesRequest(strategy.id));
                    }}
                  />
                ))}
            </Panel>
            <Panel title={"INTRADAY"}>
              {strategy
                .filter((s) => s.strategy_type == "INTRADAY")
                .map((s) => (
                  <StrategyItem
                    {...s}
                    onSelect={(strategy) => {
                      setSelectedStrategy(strategy);
                      dispatch(getSavedStrategiesRequest(strategy.id));
                    }}
                  />
                ))}
            </Panel>
          </Accordion>
        </Collapse>
        <div className="w-full">
          <div className="px-3 relative h-full w-full">
            {!isBot ? (
              <StrategyControls
                handleOnAddNode={handleOnAddNode}
                onCompile={onCompile}
                onSave={onSave}
                selectedStrategy={selectedStrategy}
              />
            ) : (
              <div
                className="absolute top-2 left-8 flex items-center cursor- z-50"
                onClick={onBackToStrategies}
              >
                <IoMdArrowBack size={16} />
                <p className="font-semibold text-sm px-2">Back to Strategies</p>
              </div>
            )}

            <ReactFlow
              nodes={nodes}
              edges={edges}
              edgeTypes={edgeTypes}
              nodeTypes={isBot ? botNodeTypes : nodeTypes}
              onConnect={onConnect}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
            >
              <Background />
              <Controls />
            </ReactFlow>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default Strategies;
