import React, { Fragment, useState } from "react";
import { BotItemsI } from ".";
import { MdAssignment } from "react-icons/md";
import AssignBot from "./AssignBot";
import AssignWatchList from "./AssignwatchList";

interface BotItemI extends BotItemsI {
  onSelect: (bot: BotItemI) => void;
}

const BotItem: React.FC<BotItemI> = (props) => {
  const {
    bot_type,
    name,
    onSelect,
    assigned: previous_assigned,
    assigned_ticker,
  } = props;
  // const [assigned, setAssigned] = useState<string[]>(
  //   assigned_ticker == "" ? previous_assigned || [] : [assigned_ticker]
  // );

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (bot_type == "SCANNER_BUY" || bot_type == "SCANNER_SELL") {
    return (
      <div>
        <div className="flex items-center cursor-pointer text-blue-500 my-2">
          <div className="w-32" onClick={() => onSelect(props)}>
            <p className="text-sm font-semibold mr-4 text-ellipsis">{name}</p>
          </div>
          <MdAssignment size={16} onClick={() => setIsOpen(true)} />
        </div>
        <div className="px-4">
          {previous_assigned.map((a, _i) => (
            <p className="">
              {_i + 1}. {a}
            </p>
          ))}
        </div>
        <AssignWatchList bot={props} isOpen={isOpen} onClose={handleClose} />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="flex items-center cursor-pointer text-blue-500 my-2">
        <div className="w-32" onClick={() => onSelect(props)}>
          <p className="text-sm font-semibold mr-4 text-ellipsis ">{name}</p>
        </div>
        {/* <MdAssignment size={16} onClick={() => setIsOpen(true)} /> */}
      </div>
    </Fragment>
  );
};

export default BotItem;
