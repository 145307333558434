import React from "react";

interface HeaderI {
  header: string;
  actionButton?: React.ReactElement;
}

const Header: React.FC<HeaderI> = (props) => {
  const { header, actionButton } = props;
  return (
    <div className="p-3 sticky top-0 z-50 bg-white shadow-md flex items-center justify-between">
      <h3 className="text-lg font-semibold">{header}</h3>
      <div>{actionButton}</div>
    </div>
  );
};

export default Header;
