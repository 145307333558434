import { createSlice } from "@reduxjs/toolkit";

interface IndicatorStateI {
  status: string;
  createIndicatorResponse: any;
  getIndicatorSuccess: any;
  compileIndicatorResponse: any;
  saveIndicatorResponse: any;
  getSavedIndicatorResponse: any;
  error: any;
}
const initialState: IndicatorStateI = {
  status: "",
  createIndicatorResponse: {},
  getIndicatorSuccess: {},
  compileIndicatorResponse: {},
  saveIndicatorResponse: {},
  getSavedIndicatorResponse: {},
  error: {},
};

const IndicatorReducer = createSlice({
  name: "Indicator",
  initialState,
  reducers: {
    createIndicatorRequest(state, action) {
      state.status = action.type;
    },
    createIndicatorSuccess(state, action) {
      state.status = action.type;
      state.createIndicatorResponse = action.payload;
    },
    createIndicatorFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    getIndicatorRequest(state, action) {
      state.status = action.type;
    },
    getIndicatorSuccess(state, action) {
      state.status = action.type;
      state.getIndicatorSuccess = action.payload;
    },
    getIndicatorFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Compile
     * @param state
     * @param action
     */
    compileIndicatorRequest(state, action) {
      state.status = action.type;
    },
    compileIndicatorSuccess(state, action) {
      state.status = action.type;
      state.compileIndicatorResponse = action.payload;
    },
    compileIndicatorFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Save Indicator Reducer Actions
     * @param state
     * @param action
     */
    saveIndicatorRequest(state, action) {
      state.status = action.type;
    },
    saveIndicatorSuccess(state, action) {
      state.status = action.type;
      state.saveIndicatorResponse = action.payload;
    },
    saveIndicatorFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },

    /**
     * Get Saved Indicator Reducer Actions
     * @param state
     * @param action
     */
    getSavedIndicatorRequest(state, action) {
      state.status = action.type;
    },
    getSavedIndicatorSuccess(state, action) {
      state.status = action.type;
      state.getSavedIndicatorResponse = action.payload;
    },
    getSavedIndicatorFailure(state, action) {
      state.status = action.type;
      state.error = action.payload;
    },
  },
});

export const {
  createIndicatorRequest,
  createIndicatorSuccess,
  createIndicatorFailure,

  getIndicatorRequest,
  getIndicatorSuccess,
  getIndicatorFailure,

  compileIndicatorRequest,
  compileIndicatorSuccess,
  compileIndicatorFailure,

  saveIndicatorRequest,
  saveIndicatorSuccess,
  saveIndicatorFailure,

  getSavedIndicatorRequest,
  getSavedIndicatorSuccess,
  getSavedIndicatorFailure
} = IndicatorReducer.actions;

export default IndicatorReducer.reducer;
