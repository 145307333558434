import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import React, { Fragment, useState } from "react";

interface CollapseI {
  children: string | React.ReactElement | React.ReactElement[];
}

const Collapse: React.FC<CollapseI> = (props) => {
  const { children } = props;
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Fragment>
      <div
        className={`relative p-2  border-r ${isExpanded ? "w-1/5" : "w-12"}`}
      >
        {isExpanded && <div>{children}</div>}
        <div
          onClick={() => setIsExpanded((preState) => !preState)}
          style={{ right: "-16px" }}
          className="collapse-btn"
        >
          {isExpanded ? <UnfoldMore /> : <UnfoldLess />}
        </div>
      </div>
    </Fragment>
  );
};

export default Collapse;
