import { NativeSelect } from "@mui/material";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import React, { Fragment } from "react";
import { Handle, Position } from "reactflow";

const OrderType = () => {
  return (
    <Fragment>
      <Handle position={Position.Right} type="source" />
      <div className="shadow-md bg-gray-50 p-2 w-52 h-32 justify-center items-center flex border rounded-md">
        {/* <BrokerIcon height={64} width={64} /> */}
        <div className="flex flex-col py-4 justify-center items-center w-full">
          <h5 className="text-lg font-semibold text-center px-3">Order Type</h5>
          <NativeSelect
            style={{ width: "90%", marginTop: "12px", marginBottom: "12px" }}
          >
            <option value={"market_buy"}>Market Buy</option>
            <option value={"market_sell"}>Market Sell</option>
            <option value={"limit_sell"}>Limit Sell</option>
            <option value={"limit_buy"}>Limit Buy</option>
          </NativeSelect>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderType;
