import React from "react";
import { IconPropsI } from ".";

const Broker: React.FC<IconPropsI> = (props) => {
  const { height = 22, width = 22 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 44 44" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1067 42.9709L16.823 24.641H16.6669C15.2924 24.6412 14.1555 24.1443 13.1094 23.2396C12.0632 22.3349 11.3689 21.0814 11.1511 19.7042L14.3912 17.5785V17.5521C14.3911 16.1033 14.9422 14.7105 15.9294 13.6641C16.9168 12.6176 18.264 11.9985 19.6902 11.9358V11.9299H20.6537V8.50766C21.3367 8.50768 21.9977 8.75318 22.5194 9.20063C23.0411 9.64811 23.3897 10.2686 23.5036 10.9521H23.544V8.50766C24.0575 8.50768 24.5617 8.64649 25.0047 8.90982C25.4478 9.17317 25.8137 9.55152 26.0649 10.006C26.3161 10.4605 26.4434 10.9746 26.434 11.4956C26.4243 12.0166 26.2781 12.5256 26.0106 12.9702C26.8808 13.5474 27.5954 14.3357 28.0902 15.2638C28.5847 16.1918 28.8435 17.2303 28.843 18.2854V40.4075C28.843 40.7318 28.97 41.0427 29.1957 41.2717C29.4217 41.5011 29.728 41.6297 30.0473 41.6297H31.4757C29.4696 42.6632 27.2843 43.3889 24.9791 43.7464C35.3846 42.1329 43.3557 33.0111 43.3557 21.9999C43.3557 9.84971 33.6501 0 21.6778 0C9.70548 0 0 9.84971 0 21.9999C0 31.8255 6.34679 40.1466 15.1067 42.9709ZM17.6563 18.116C17.5323 18.242 17.4625 18.4127 17.4622 18.5908L17.4015 19.2637H18.7262C19.0776 19.2637 19.4146 19.122 19.663 18.8698C19.9114 18.6176 20.051 18.2756 20.051 17.9189H18.1241C17.9486 17.9192 17.7804 17.9901 17.6563 18.116Z"
        fill="#FCD72B"
      ></path>
    </svg>
  );
};

export default Broker;
