import {
  Collapse,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
} from "@mui/material";

import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Handle, Position } from "reactflow";
import {
  IoIosArrowDropdown,
  IoIosArrowDropup,
  IoIosArrowUp,
} from "react-icons/io";
import {
  changeNodeData,
  changeNodeParams,
  deleteNode,
} from "../../../../redux/reducers/GraphicalReducer";
import { IoTrash } from "react-icons/io5";
import { useToggle } from "usehooks-ts";

const Indicator = (props: any) => {
  const dispatch = useDispatch();
  const [open, toggleOpen, setOpen] = useToggle();

  return (
    <Fragment>
      <Handle position={Position.Right} type="source" />
      <div className="shadow-md bg-gray-50 p-2 w-56 border rounded-md">
        {/* <h5 className="text-center text-sm">Technical Indicators</h5> */}
        <div className="flex justify-center">
          <TextField
            variant="standard"
            defaultValue={props?.data?.nodeName}
            inputProps={{ style: { textAlign: "center" } }}
            style={{ width: "50%" }}
            onChange={(e) => {
              let requestObj = {
                id: props?.id,
                data: { nodeName: e.target?.value },
              };
              dispatch(changeNodeData(requestObj));
            }}
          />
        </div>

        <div className="flex justify-center items-center">
          <h5 className="text-lg font-semibold text-center px-3">
            {props?.data?.name}
          </h5>
          <IoTrash
            size={20}
            className="cursor-pointer text-red-600"
            onClick={() => dispatch(deleteNode(props?.id))}
          />
        </div>

        <div className="flex justify-center items-center py-2">
          {!open && (
            <IoIosArrowDropdown
              className="cursor-pointer"
              size={20}
              onClick={() => toggleOpen()}
            />
          )}
        </div>
        <Collapse in={open} unmountOnExit timeout={"auto"}>
          <div className="flex pb-2">
            <FormControl fullWidth variant="outlined">
              <InputLabel variant="standard">Time Frame</InputLabel>
              <NativeSelect
                onChange={(e) => {
                  let requestObj = {
                    id: props?.id,
                    data: { timeframe: e.target.value },
                  };
                  dispatch(changeNodeData(requestObj));
                }}
              >
                <option value={"1 T"}>1Min</option>
                <option value={"5 T"}>5Min</option>
                <option value={"10 T"}>10Min</option>
                <option value={"15 T"}>15Min</option>
                <option value={"30 T"}>30Min</option>
                <option value={"1 H"}>1hour</option>
                <option value={"4 H"}>4hour</option>
                <option value={"1 D"}>1day</option>
                <option value={"1 W"}>1week</option>
              </NativeSelect>
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <InputLabel variant="standard">Shift Position</InputLabel>
              <NativeSelect
                onChange={(e) => {
                  let requestObj = {
                    id: props?.id,
                    data: { shift: e.target?.value },
                  };
                  dispatch(changeNodeData(requestObj));
                }}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </NativeSelect>
            </FormControl>
          </div>
          <div>
            <FormControl fullWidth variant="outlined">
              <InputLabel variant="standard">Trigger On</InputLabel>
              <NativeSelect
                onChange={(e) => {
                  let requestObj = {
                    id: props?.id,
                    calculateOn: { shift: e.target?.value },
                  };
                  dispatch(changeNodeData(requestObj));
                }}
              >
                <option value={"ON_CANDLE_CLOSE"}>On Candle Close</option>
                <option value={"ON_PRICE_CHANGE"}>On Price Change</option>
              </NativeSelect>
            </FormControl>
          </div>
          {Object.values(props?.data?.params).map((p: any) => (
            <div className="py-2">
              <FormControl fullWidth variant="outlined">
                <TextField
                  variant="standard"
                  label={p?.name}
                  defaultValue={p?.value}
                  fullWidth
                  onChange={(e) => {
                    let requestObj = {
                      id: props?.id,
                      data: {
                        [p?.key]: { name: p?.name, value: e.target?.value },
                      },
                    };
                    dispatch(changeNodeParams(requestObj));
                  }}
                />
              </FormControl>
            </div>
          ))}
          <div className="flex justify-center">
            {open && (
              <IoIosArrowDropup
                className="cursor-pointer"
                size={20}
                onClick={() => toggleOpen()}
              />
            )}
          </div>
        </Collapse>
      </div>
    </Fragment>
  );
};

export default Indicator;
