import React, { Fragment, useEffect, useRef, useState } from "react";
import AdminWrapper from "../AdminWrapper";
import Collapse from "../../components/Collapse";
import { ListHeading } from "baseui/list";
import { Button } from "baseui/button";
import { useToggle } from "usehooks-ts";
import CreateIndicator from "./CreateIndicator";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  compileIndicatorRequest,
  getIndicatorRequest,
  getSavedIndicatorRequest,
  saveIndicatorRequest,
} from "../../redux/reducers/IndicatorReducer";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { IndicatorI, IndicatorParamsI } from "./types";
import { Accordion, Panel } from "baseui/accordion";
import IndicatorItem from "./IndicatorItem";
import { Editor } from "@monaco-editor/react";
import { editor } from "monaco-editor";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { toast } from "react-toastify";
import { ColorType, createChart, IChartApi } from "lightweight-charts";
import moment from "moment";

const Indicator = () => {
  const [open, toggleOpen, setOpen] = useToggle();
  const IndicatorState = useSelector((state: RootState) => state.Indicator);
  const [userIndicator, setUserIndicator] = useState<IndicatorI[]>([]);
  const [globalIndicator, setGlobalIndicator] = useState<IndicatorI[]>([]);
  const [selectedIndicator, setSelectedIndicator] = useState<
    IndicatorI | undefined
  >();
  const [code, setCode] = useState<string | undefined>();
  const chartContainer = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<IChartApi>();

  const [params, setParams] = useState<IndicatorParamsI[]>([]);

  const dispatch = useDispatch();
  const { enqueue } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getIndicatorRequest({}));
  }, []);

  const getData = () => {
    const { output, output1, t, o, c, h, l } =
      IndicatorState.compileIndicatorResponse?.output?.candles;
    let prices = [];
    let indicator = [];
    let indicator1 = [];
    for (let i = 0; i < output?.length; i++) {
      prices.push({
        time: t[i],
        high: h[i],
        low: l[i],
        open: o[i],
        close: c[i],
      });
      indicator.push({
        time: t[i],
        value: output?.[i],
      });
      indicator1.push({
        time: t[i],
        value: output1?.[i],
      });
    }
    return {
      prices: prices.reverse(),
      indicator: indicator.reverse(),
      indicator1: indicator1.reverse(),
    };
  };

  const plotChart = () => {
    chartInstance.current?.remove();
    if (chartContainer.current) {
      const { prices, indicator, indicator1 } = getData();
      console.log("Indicator 1", indicator1);
      const chart = createChart(chartContainer.current, {
        layout: {
          background: { type: ColorType.Solid, color: "#fff" },
          textColor: "#000",
        },
        width: chartContainer.current.clientWidth,
        height: 200,
        localization: {
          locale: "en-US",
          timeFormatter: (time: any) => {
            return moment(time).format("DD-MM-YYYY HH:mm A");
          },
        },
        timeScale: {
          timeVisible: true,
          tickMarkFormatter: (time: any) => {
            const d = new Date(time * 1000);
            let local =
              Date.UTC(
                d.getFullYear(),
                d.getMonth(),
                d.getDate(),
                d.getHours(),
                d.getMinutes(),
                d.getSeconds(),
                d.getMilliseconds()
              ) / 1000;
            return moment(local).format("hh:mm A");
          },
        },
      });
      chartInstance.current = chart;
      const candlestickSeries = chart.addCandlestickSeries({
        upColor: "#26a69a",
        downColor: "#ef5350",
        borderVisible: false,
        wickUpColor: "#26a69a",
        wickDownColor: "#ef5350",
      });

      candlestickSeries.setData(prices);

      const lineSeries = chart.addLineSeries({
        color: "#2962FF",
        lineWidth: 1,
      });
      lineSeries.setData(indicator);

      const lineSeries1 = chart.addLineSeries({
        color: "#d00000",
        lineWidth: 1,
      });
      lineSeries1.setData(indicator1);
    }
  };

  useEffect(() => {
    switch (IndicatorState.status) {
      case "Indicator/createIndicatorSuccess":
        handleClose();
        enqueue(
          { message: IndicatorState.createIndicatorResponse?.message },
          DURATION.medium
        );
        dispatch(getIndicatorRequest({}));
        break;
      case "Indicator/getIndicatorSuccess":
        setUserIndicator(IndicatorState.getIndicatorSuccess?.userIndicator);
        setGlobalIndicator(IndicatorState.getIndicatorSuccess?.globalIndicator);
        break;
      case "Indicator/compileIndicatorSuccess":
        enqueue(
          { message: "Indicator compiled successfully" },
          DURATION.medium
        );
        plotChart();
        break;
      case "Indicator/getSavedIndicatorRequest":
        setParams([]);
        setCode("");
        break;
      case "Indicator/getSavedIndicatorSuccess":
        setParams(IndicatorState.getSavedIndicatorResponse?.data?.params);
        setCode(IndicatorState.getSavedIndicatorResponse?.data?.code);
        break;
    }
  }, [IndicatorState.status]);

  const handleCodeChange = (
    value: string | undefined,
    ev: editor.IModelContentChangedEvent
  ) => {
    setCode(value);
  };

  const onCompile = () => {
    let paramsObj = Object.create({});
    params.map((p) => {
      Object.assign(paramsObj, { [p.key]: p.value });
    });
    let requestObj = {
      code,
      params: paramsObj,
    };

    dispatch(compileIndicatorRequest(requestObj));
  };

  const onSave = () => {
    let requestObj = {
      params: params,
      code: code,
      indicator: selectedIndicator,
    };
    dispatch(saveIndicatorRequest(requestObj));
  };

  const onAddParams = () => {
    setParams((preState) => {
      let newState = preState.slice();
      newState.push({ key: "name", value: "" });
      return newState;
    });
  };

  return (
    <AdminWrapper>
      <CreateIndicator isOpen={open} handleClose={handleClose} />
      <div className="flex relative pt-6 px-2 h-full">
        <Collapse>
          <ListHeading
            heading="Indicator"
            endEnhancer={() => (
              <Button
                onClick={toggleOpen}
                style={{ alignSelf: "center" }}
                size="mini"
              >
                Create
              </Button>
            )}
            maxLines={1}
          />
          <Accordion>
            <Panel title="User Indicator">
              {userIndicator.map((i) => (
                <IndicatorItem
                  {...i}
                  keyItem={i.key}
                  onSelect={(indicator) => {
                    setSelectedIndicator(indicator);
                    dispatch(getSavedIndicatorRequest(indicator.key));
                  }}
                />
              ))}
            </Panel>
            <Panel
              title="Global Indicator"
              overrides={{
                Content: { style: { height: "65vh", overflowY: "scroll" } },
              }}
            >
              {globalIndicator.map((i) => (
                <IndicatorItem
                  {...i}
                  keyItem={i.key}
                  onSelect={(indicator) => {
                    setSelectedIndicator(indicator);
                    dispatch(getSavedIndicatorRequest(indicator.key));
                  }}
                />
              ))}
            </Panel>
          </Accordion>
        </Collapse>
        <div className="w-full">
          {selectedIndicator ? (
            <div className="h-full">
              <div className="pb-8 pl-8 flex justify-between">
                <p>
                  <span className="font-semibold">Name:</span>{" "}
                  {selectedIndicator?.name}
                  <span className="font-semibold pl-6">Description: </span>
                  {selectedIndicator?.description}
                </p>

                <div className="flex">
                  <Button size="compact" onClick={onCompile}>
                    Compile
                  </Button>
                  <div className="px-2">
                    <Button size="compact" onClick={onSave}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="w-3/4">
                  <div style={{ height: "50vh" }}>
                    <Editor
                      language="python"
                      value={code}
                      onChange={handleCodeChange}
                    />
                  </div>
                  <div ref={chartContainer} className="px-4 border-t"></div>
                </div>
                <div className="w-1/4">
                  <div className="flex">
                    <ListHeading
                      heading={"Parameters"}
                      endEnhancer={
                        <Button size="compact" onClick={onAddParams}>
                          Add
                        </Button>
                      }
                    />
                  </div>
                  {params.map((p, index) => (
                    <div className="flex px-3 py-2">
                      <div className="pr-2">
                        <Input
                          size="compact"
                          placeholder={"Key"}
                          value={p.key}
                          onChange={(e) => {
                            setParams((preState) => {
                              let newState = preState.slice();
                              newState[index] = {
                                ...newState[index],
                                key: e.target.value,
                              };
                              return newState;
                            });
                          }}
                        />
                      </div>
                      <div className="pl-2">
                        <Input
                          size="compact"
                          placeholder={"Value"}
                          value={p.value}
                          onChange={(e) => {
                            setParams((preState) => {
                              let newState = preState.slice();
                              newState[index] = {
                                ...newState[index],
                                value: e.target.value,
                              };
                              return newState;
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </AdminWrapper>
  );
};

export default Indicator;
