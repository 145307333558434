import React from "react";
import { IndicatorI } from "./types";
import { ListItemLabel } from "baseui/list";

interface IndicatorItemsI extends IndicatorI {
  onSelect: (indicator: IndicatorI) => void;
  keyItem: string;
}

const IndicatorItem: React.FC<IndicatorItemsI> = (props) => {
  const { name, description, id, keyItem, onSelect } = props;

  return (
    <div
      className="cursor-pointer"
      onClick={() => onSelect({ name, description, id, key: keyItem })}
    >
      <ListItemLabel description={description}>{name}</ListItemLabel>
    </div>
  );
};

export default IndicatorItem;
