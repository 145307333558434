import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import Preloader from "./features/Preloader";
import { getUserFromTokenRequest } from "./redux/reducers/AuthReducer";
import Login from "./features/auth/Login";
import LoginOTP from "./features/auth/LoginOTP";
import Dashboard from "./features/Dashboard";
import NotFound from "./features/NotFound";
import Chart from "./features/Chart";
import ManageBots from "./features/bots";
import GraphicalEditor from "./features/bots/editor";
import AkashLogin from "./features/bots/Admin/Login";
import WatchList from "./features/Watchlist";
import FlowEditor from "./features/bots/FlowEditor";
import Strategies from "./features/Strategies";
import Indicator from "./features/Indicator";
import Signup from "./features/auth/Signup";
import SignupOTP from "./features/auth/SignupOTP";

const App = () => {
  const AuthState = useSelector((state: RootState) => state.Auth);
  const dispatch = useDispatch();

  const routes = AuthState?.user
    ? createBrowserRouter([
        { path: "/", element: <Dashboard /> },
        { path: "/charts", element: <Chart /> },
        { path: "/watchlist", element: <WatchList /> },
        { path: "/bots/graphical-editor", element: <FlowEditor /> },
        { path: "/strategies", element: <Strategies /> },
        { path: "/indicators", element: <Indicator /> },
        { path: "*", element: <NotFound /> },
      ])
    : createBrowserRouter([
        { path: "/", element: <Login /> },
        { path: "/login/otp", element: <LoginOTP /> },
        { path: "/login/akash", element: <AkashLogin /> },
        { path: "/signup", element: <Signup /> },
        { path: "/signup/otp", element: <SignupOTP /> },
      ]);

  useEffect(() => {
    dispatch(getUserFromTokenRequest({}));
  }, []);

  if (AuthState.isLoading) {
    return <Preloader />;
  }
  return <RouterProvider router={routes} />;
};

export default App;
