import { all } from "redux-saga/effects";
import BotsSaga from "./BotsSaga";
import AuthSaga from "./AuthSaga";
import SymbolSaga from "./SymbolSaga";
import StrategiesSaga from "./StrategiesSaga";
import IndicatorSaga from "./IndicatorSaga";

const combinedSaga = [
  ...BotsSaga,
  ...AuthSaga,
  ...SymbolSaga,
  ...StrategiesSaga,
  ...IndicatorSaga,
];

export function* RootSaga() {
  yield all(combinedSaga);
}
